/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Riderdatatable.css";
import Banner2 from "../Banner/Banner2";
import Baseurl from "../BaseURL/Baseurl";
import "./page.css";
import userfake from "./user-removebg-preview.png";

function Riderrace() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [data1, setData1] = useState([]);
  // const [data2, setData2] = useState([]);
  const [, setError] = useState(null);
  const [key, setKeys] = useState(null);
  const [key1, setKeys1] = useState(null);
  const [length, setLength] = useState(null);
  // const [key2, setKeys2] = useState(null);

  const { id } = useParams();
  // const { state } = useLocation();
  const retrievedData = JSON.parse(localStorage.getItem("RacereName"));
  console.log(retrievedData.item.Driver, "usamaid=======>");
  const Usama = async () => {
    try {
      const response = await axios.get(
        `${Baseurl.baseurl}api/get-race-data/${id}`
      );
      setData(response?.data?.csv_data);
      setKeys(Object.keys(response?.data?.csv_data?.[0]));
      console.log(Object.keys(response?.data?.csv_data?.[0]), "usamaconsole");
    } catch (err) {
      setError(err.message);
    }
  };
  const Usama1 = async () => {
    try {
      const response = await axios.get(
        `${Baseurl.baseurl}api/get-race-data/${id}`
      );
      console.log(response?.data.additional_data, "usamakhnsss");
      console.log(response?.data.additional_data?.length, "usamakhnsss");
      setLength(response?.data.additional_data?.length);
      setData1(response?.data.additional_data);

      setKeys1(Object.keys(response?.data.additional_data));
      console.log(Object.keys(response?.data.additional_data), "usamaconsole");
    } catch (err) {
      setError(err.message);
    }
  };
  // const Usama2 = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${Baseurl.baseurl}api/get-race-data/${id}`
  //     );
  //     setData2(response?.data?.csv_data2?.csv_data);
  //     console.log(response?.data, "esponse?.data?.csv_data2?.csv_data");
  //     setKeys2(Object.keys(response?.data?.csv_data2?.csv_data?.[0]));
  //     console.log(
  //       Object.keys(response?.data?.csv_data2?.csv_data?.[0]),
  //       "usamaconsole"
  //     );
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };
  useEffect(() => {
    Usama();
    Usama1();
    // Usama2();
    window.scrollTo(0, 0);
  }, []);

  console.log(data?.[0]?.Driver, "indexuk");
  useEffect(() => {
    const fakeAsyncAction = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulating a 2-second delay
      setLoading(false);
    };

    fakeAsyncAction();
  }, []);
  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };
  return (
    <div className="bg-black">
      {/* {loading && <Loader />} */}
      <Banner2
        title="Racing"
        slug="Informations"
        backgroundImage="/assets/images/banner/video-banner-1.jpg"
      />
      <div className="container">
        <div className="gradient-cardss">
          <div className="carddreward">
            <div className="container-cardd bg-green-box">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <p className="card-titles underline-small-h1">
                  {retrievedData?.item.Driver}
                </p>

                <img
                  src={`https://${retrievedData.item.Rider_Country_Img}`} // Updated line
                  alt="Country"
                  style={{
                    scale: "1.7",
                    marginTop: "10px",
                    border: "solid 1px white",
                  }}
                  onLoad={() =>
                    console.log(
                      `Image loaded: ${retrievedData.item.Rider_Country_Img}`
                    )
                  }
                  onError={() =>
                    console.error(
                      `Error loading image: ${retrievedData.item.Rider_Country_Img}`
                    )
                  }
                />
              </div>
              <img
                src={
                  retrievedData.item.rider_image || userfake
                  // "/assets/images/bg/martin-truex-jr-212x290.webp"
                }
                alt="rider"
                style={{
                  position: "absolute",
                  top: "-29px",
                  scale: "0.7",
                  right: "-20px",
                  borderRadius: "60px",
                }}
                onLoad={() =>
                  console.log(`Image loaded: ${retrievedData.item.rider_image}`)
                }
                onError={() =>
                  console.error(
                    `Error loading image: ${retrievedData.item.rider_image}`
                  )
                }
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <p className="card-description">
                  <h5 style={{ color: "rgb(238, 18, 67)" }}>Born</h5>
                  <h6 className="card-titles" style={{ fontSize: "20px" }}>
                    {retrievedData.item.Born}
                  </h6>
                </p>
                <p className="card-description">
                  <h5 style={{ color: "rgb(238, 18, 67)" }}>Home</h5>
                  <h6 className="card-titles" style={{ fontSize: "20px" }}>
                    {retrievedData.item.Home}
                  </h6>
                </p>
                <p className="card-description">
                  <h5 style={{ color: "rgb(238, 18, 67)" }}>Died</h5>
                  <h6 className="card-titles " style={{ fontSize: "20px" }}>
                    {retrievedData.item.Died}
                    {console.log(retrievedData, "datad")}
                  </h6>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{
          border: "solid",
          padding: "10px",
          marginTop: "10px",
          borderRadius: "5px",
        }}
      >
        <>
          <div className="tablees-container">
            {loading ? (
              <Loader />
            ) : (
              <>
                <table className="table table-dark table-striped table-bordered border-light">
                  <thead>
                    <tr style={{ width: "auto", textAlign: "center" }}>
                      {key &&
                        key
                          .filter(
                            (k) =>
                              k !== "3rdpageuuid" &&
                              data.some((item) => item[k] !== null)
                          ) // Exclude the specific value and columns with null values
                          .map((k) => (
                            <th
                              key={k}
                              scope="col"
                              style={{
                                width: "auto",
                                background: "#ee1243",
                                fontWeight: "bold",
                              }}
                            >
                              {k}
                            </th>
                          ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item) => (
                      <tr key={item.id} style={{ width: "100%" }}>
                        {key
                          .filter(
                            (k) => k !== "3rdpageuuid" && item[k] !== null
                          ) // Exclude the specific value and columns with null values
                          .map((k) => (
                            <td key={k} style={{ textAlign: "center" }}>
                              {k === "Driver" ? (
                                item[k]?.map((driverItem, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                      width: "200px",
                                    }}
                                  >
                                    &nbsp; &nbsp;
                                    <img
                                      src={`https://${driverItem[0]}`}
                                      alt="Country"
                                    />
                                    &nbsp; &nbsp;
                                    <span>{driverItem[1]}</span>
                                  </div>
                                ))
                              ) : k === "Status" && item[k] === "running" ? (
                                <span style={{ color: "green" }}>
                                  {item[k]}
                                </span>
                              ) : k === "Status" && item[k] === "crash" ? (
                                <span style={{ color: "red" }}>{item[k]}</span>
                              ) : k === "Status" &&
                                item[k] === "did not start" ? (
                                <span style={{ color: "yellow" }}>
                                  {item[k]}
                                </span>
                              ) : (
                                item[k]
                              )}
                            </td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* -----Csv-Data1------  */}
                {data1?.map((item, i) => (
                  <div key={i}>
                    {" "}
                    {item.table_name && (
                      <p
                        className="tablnename text-center"
                        style={{ background: "gray", color: "white" }}
                      >
                        <b>{item.table_name.replaceAll("_", " ")}</b>
                      </p>
                    )}
                    <table className="table table-dark table-striped table-bordered border-light">
                      {console.log("itemssss", item)}
                      <thead>
                        <tr style={{ width: "auto", textAlign: "center" }}>
                          {Object.keys(item.table_data[0]).map((key, index) => (
                            <th
                              key={index}
                              scope="col"
                              style={{
                                width: "auto",
                                background: "#ee1243",
                                fontWeight: "bold",
                              }}
                            >
                              {key}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {item.table_data.map((rowData, rowIndex) => (
                          <tr key={rowIndex}>
                            {Object.entries(rowData).map(
                              ([key, value], cellIndex) => {
                                if (key === "Condition") {
                                  const isURL = /^www\./.test(value); // Check if value contains "www."
                                  const urls = isURL
                                    ? value.split(",").map((url) => url.trim())
                                    : [];
                                  return (
                                    <td
                                      key={cellIndex}
                                      style={{ textAlign: "center" }}
                                    >
                                      {urls.length > 0 ? (
                                        <img
                                          src={`https://${urls[0]}`}
                                          alt="flag"
                                        />
                                      ) : (
                                        <td style={{ textAlign: "center" }}>
                                          {value}
                                        </td>
                                      )}
                                    </td>
                                  );
                                }

                                // For other keys, simply render the value
                                return (
                                  <td
                                    key={cellIndex}
                                    style={{ textAlign: "center" }}
                                  >
                                    {value}
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </>
            )}
          </div>
          <br />
        </>
      </div>
    </div>
  );
}

export default Riderrace;
