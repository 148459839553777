import React, { useEffect } from 'react'
import RandomTable from '../../Components/RandomTable/page'
import Banner2 from '../../Components/Banner/Banner2'

const Page = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Banner2 title="WELCOME TO RANDOM RACE" backgroundImage="/assets/images/banner/video-banner-1.jpg"/>
    {/* <DriverSec3/> */}
    <RandomTable/>
    </>
  )
}

export default Page