/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import Baseurl from "../BaseURL/Baseurl";
import { useDispatch, useSelector } from "react-redux";
import { setTruck, selectTruck } from "../../Redux/Reducer/Reducer";
import { Link, useNavigate } from "react-router-dom";

const CupTable = () => {
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const truck = useSelector(selectTruck);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Stop loader once data is fetched
      try {
        const response = await axios.get(`${Baseurl.baseurl}get-trucks/`);
        console.log(response?.data, "dataIsma");
        // setData(response?.data.trucks_data);
        dispatch(setTruck(response?.data.trucks_data));
        setLoading(false); // Stop loader once data is fetched
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    console.log(truck, "truck");
    if (!truck.length) {
      fetchData();
    }
  }, [dispatch, truck]);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  const divStyle = {
    backgroundImage: 'url("/assets/images/bg/1.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    // height: '100vh',
    // Add any other styles you want for the container
  };

  return (
    <>
      <section style={divStyle} className="about-area pb-60 pt-60">
        <div className="container">
          <section className="about-area pb-60 pt-60">
            <div className="tablees-container">
              {loading ? (
                <Loader />
              ) : (
                <table className="table table-dark table-striped table-bordered border-light">
                  <thead>
                    {truck && truck.length > 0 && (
                      <tr>
                        {Object.entries(truck[0])
                          .filter(([key]) => key !== "year_uuid")
                          .map(([key], i) => (
                            <th
                              style={{
                                textAlign: "center",
                                background: "#ee1243",
                                width: "auto",
                                padding: "5px",
                              }}
                              key={i}
                            >
                              {key}
                            </th>
                          ))}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {truck &&
                      truck.length > 0 &&
                      truck.map((row, index) => (
                        <tr key={index}>
                          {Object.entries(row)
                            .filter(([key]) => key !== "year_uuid")
                            .map(([key, value], i) => (
                              <td
                                key={i}
                                style={{
                                  textAlign: "center",
                                  width: "auto",
                                  padding: "5px",
                                }}
                              >
                                {key !== "Year" &&
                                key !== "usama" &&
                                key !== "usama" ? (
                                  // If the key is not 'Champion', you can customize the content here or leave it empty
                                  <div>{value}</div>
                                ) : (
                                  // If the key is 'Champion', make it clickable
                                  <a
                                    onClick={() => {
                                      console.log(row.year_uuid, "year_uuid");
                                      navigate(`/TruckSec/${row.year_uuid}`, {
                                        state: {
                                          /* Adjust this object as needed */
                                        },
                                      });
                                    }}
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      color: "white",
                                      cursor: "pointer",
                                      textDecoration: "none",
                                      display: "block", // Set display to block to make each value appear on a new line
                                    }}
                                    className="colorhover"
                                  >
                                    {typeof value === "object" ? (
                                      Object.values(value).map((val, index) => (
                                        <div key={index}>{val}</div>
                                      ))
                                    ) : (
                                      <div>{value}</div>
                                    )}
                                  </a>
                                )}
                              </td>
                            ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default CupTable;
