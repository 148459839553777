import React, { useEffect } from "react";
import Section3 from "../../Components/HomeSec3/page";
import Section5 from "../../Components/HomeSec5/page";
import Section6 from "../../Components/HomeSec6/page";
import Banner from "../../Components/Banner/Banner";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Banner title="COMPREHENSIVE" slug="RACING STATISTICS" />
      <Section3 /> 
      <Section5 />
      {/* responsive Done */}
      <Section6 />
    </>
  );
};

export default Home;
