import React, { useState, useEffect } from "react";
import axios from "axios";
import Baseurl from "../BaseURL/Baseurl";
import { Link, useParams } from "react-router-dom";

const Ownertwo = () => {
  const [ownerData, setOwnerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { uuid } = useParams();
  const [tablename, setTablename] = useState(null);
  const [ownername, setownername] = useState(null);


  useEffect(() => {
    const fetchOwnerData = async () => {
      try {
        const response = await axios.get(
          `${Baseurl.baseurl}get-owner-second-page/${uuid}`
        );
        console.log(response.data.all_tables_data[0].table_data[1], "Owner2");
        setOwnerData(response.data.all_tables_data || []); // Ensure ownerData is always an array
        setTablename(response.data.all_tables_data[0]?.table_name);
        setownername(response.data.owner_name);
        setLoading(false);
      } catch (err) {
        console.error(err.response || err.message);
        setError(err.response?.data?.message || err.message);
        setLoading(false);
      }
    };

    fetchOwnerData();
  }, [uuid]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, []);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };


  const displayName = ownername || "No Owner Name Provided";

  return (
    <section className="about-area pb-60 pt-60 bg-black">
      <div className="container">
        <section className="about-area pb-60 pt-60">
          <h2 className="pb-3 text-white text-center">{displayName}</h2>
          {/* <h5 className="pb-3 text-white text-center">{formattedTablename}</h5> */}
      <div className="tablees-container">
  {loading ? (
    <Loader />
  ) : (
    <>
      {ownerData.length > 0 && ownerData.map((item, itemIndex) => {
        // Format the table name by replacing underscores with spaces
        const formattedTableName = item.table_name
          ? item.table_name.replace(/_/g, " ")
          : `Table ${itemIndex + 1}`;
          
        return (
          <div key={itemIndex} className="table-wrapper">
            {/* Display Formatted Table Name */}
            <h3 className="pb-3 text-white text-center">
              {formattedTableName}
            </h3>
            
            <table className="table table-dark table-striped table-bordered border-light">
              <thead>
                {item.table_data.length > 0 && (
                  <tr>
                    {Object.keys(item.table_data[0] || {})
                      .slice(0, -4) // Adjust as needed to exclude unwanted keys
                      .map((key) => (
                        <th
                          style={{
                            textAlign: "center",
                            background: "#EE1242",
                            width: "auto",
                            padding: "5px",
                          }}
                          key={key}
                        >
                          {key.replace(/_/g, " ")} {/* Replace underscores with spaces */}
                        </th>
                      ))}
                  </tr>
                )}
              </thead>
              <tbody>
                {item.table_data.length > 0 && (
                  <>
                    {item.table_data.map((row, rowIndex) => {
                      const isLastRow = rowIndex === item.table_data.length - 1;

                      return (
                        <tr key={`${itemIndex}-${rowIndex}`}>
                          {Object.entries(row)
                            .slice(0, -4) // Adjust to exclude unwanted keys if needed
                            .map(([key, value], valueIndex) => (
                              <td
                                style={{
                                  textAlign: "center",
                                  width: "auto",
                                  padding: "5px",
                                }}
                                key={valueIndex}
                              >
                                {key === "Year" && !isLastRow ? (
                                  <Link
                                    to={`/Ownerthree/${row.year_uuid}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "white",
                                    }}
                                  >
                                    {value}
                                  </Link>
                                ) : key === "Races" || key === "usama" ? (
                                  <Link
                                    to={`/Ownerthree/${
                                      key === "Races"
                                        ? row.race_uuid
                                        : row.usama_uuid
                                    }`}
                                    style={{
                                      textDecoration: "none",
                                      color: "white",
                                    }}
                                  >
                                    {value}
                                  </Link>
                                ) : (
                                  value
                                )}
                              </td>
                            ))}
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  )}
</div>

        </section>
      </div>
    </section>
  );
};

export default Ownertwo;
