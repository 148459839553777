import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

const Header = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    handleClose(); // Close the sidebar when a link is clicked
  };
  useEffect(() => { // Show the sidebar
    window.scrollTo(0, 0); // Show the sidebar
  }, []);
  return (
    <>
      <header className="header-area header-1" data-aos="slide-down">
        {/* <!-- Start mobile menu --> */}
        <div className="mobile-menu">
          <div className="container">
            <div className="mobile-menu-wrapper"></div>
          </div>
        </div>
        {/* <!-- End mobile menu --> */}

        <div className="main-responsive-nav">
          <div className="container">
            {/* <!-- Mobile Logo --> */}
            <div className="logo">
              <Link to="/">
                <img src="/assets/images/logo/logo.png" alt="logo" />
              </Link>
            </div>
            {/* <!-- Menu toggle button --> */}
            <Button
              className="menu-toggler"
              variant="primary"
              onClick={handleShow}
            >
              <span></span>
              <span></span>
              <span></span>
            </Button>
            <Offcanvas
              style={{ background: "#4f4f4f" }}
              show={show}
              onHide={handleClose}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{ color: "white" }}>
                  {" "}
                  <div class="footer-widget-heading">
                    <h3>Racing Ref</h3>
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <ul id="mainMenu" className="navbar-nav mobile-item mx-auto">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="nav-link toggle"
                      onClick={handleLinkClick}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/driver"
                      onClick={handleLinkClick}
                    >
                      Drivers
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/owner"
                      onClick={handleLinkClick}
                    >
                      Owners
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/track"
                      onClick={handleLinkClick}
                    >
                      Tracks
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/cup"
                      onClick={handleLinkClick}
                    >
                      Cup
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/xfinity"
                      onClick={handleLinkClick}
                    >
                      Xfinity
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/truck"
                      onClick={handleLinkClick}
                    >
                      Trucks
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/arca"
                      onClick={handleLinkClick}
                    >
                      Arca
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/modifier"
                      onClick={handleLinkClick}
                    >
                      Modified
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/imsa"
                      onClick={handleLinkClick}
                    >
                      IMSA
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/indycar"
                      onClick={handleLinkClick}
                    >
                      IndyCar
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/f1"
                      onClick={handleLinkClick}
                    >
                      F1
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      href="contact.html"
                      onClick={handleLinkClick}
                    >
                      Fantasy
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link toggle"
                      to="/random"
                      onClick={handleLinkClick}
                    >
                      Random
                    </Link>
                  </li> */}
                  {/* ... Repeat for other links ... */}
                </ul>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>

        <div className="main-navbar">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              {/* <!-- Logo --> */}
              <Link className="navbar-brand" to="/">
                {/* <!-- <img src="assets/images/logo/logo-1.png" alt="Logo"> --> */}
                <h2 className="text-light">Racing Ref</h2>
              </Link>
              {/* <!-- Navigation items --> */}
              <div className="collapse navbar-collapse">
                <ul id="mainMenu" className="navbar-nav mobile-item mx-auto">
                  <li className="nav-item">
                    <Link to="/" className="nav-link toggle text-light">
                      Home
                      {/* <i className="fal fa-plus"></i> */}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/driver">
                      Drivers
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/owner">
                      Owners
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/track">
                      Tracks
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/cup">
                      Cup
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/xfinity">
                      Xfinity
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/truck">
                      Trucks
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/arca">
                      Arca
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/modifier">
                      Modified
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/imsa">
                      IMSA
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/indycar">
                      IndyCar
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/f1">
                      F1
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link" href="contact.html">
                      Fantasy
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link toggle" to="/random">
                      Random
                    </Link>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
