import React from "react";

const ModifierTable = () => {
  return (
    <>
      <section className="about-area pb-60 pt-60">
        <div className="container">
        <h2 className='pb-1' data-aos="fade-up" data-aos-delay="100">1995 MOLSON GRAND PRIX OF CANADA</h2>
        <p className='pb-1 lh-base' data-aos="fade-up" data-aos-delay="100">Prior to 1950 Formula One was known as the European Drivers Championship and was sanctioned by AIACR, the forerunner to FIA.</p>
       
          <table className="table  table-dark table-striped table-bordered border-light">
            <thead>
        
              <tr style={{ width: "100%" }}>
                <th scope="col"  style={{ textAlign: "center" }}>
               POS
                </th>
                <th scope="col"  style={{ textAlign: "center" }}>
                ST
                </th>
                <th scope="col"  style={{ textAlign: "center"}}>
                #
                </th>
                <th scope="col"  style={{ textAlign: "center"}}>
                Driver
                </th>
                <th scope="col"  style={{ textAlign: "center"}}>
                TEAM
                </th>
                <th scope="col"  style={{ textAlign: "center"}}>
                CHASS./ENG.
                </th>
                <th scope="col"  style={{ textAlign: "center"}}>
                LAPS
                </th>
                <th scope="col"  style={{ textAlign: "center"}}>
                STATUS
                </th>
                <th scope="col"  style={{ textAlign: "center"}}>
                LED
                </th>
                <th scope="col"  style={{ textAlign: "center"}}>
                PTS
                </th>
          
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>1</td>
                <td style={{ textAlign: "center" }}>5</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>  Jean Alesi</td>
                <td style={{ textAlign: "center" }}>Scuderia Ferrari SpA</td>
                <td style={{ textAlign: "center" }}>Ferrari 412T2 / Ferrari Type 044/1</td>
                <td style={{ textAlign: "center" }}>68</td>
                <td style={{ textAlign: "center" }}>1:44:54.171</td>
                <td style={{ textAlign: "center" }}>11</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>2</td>
                <td style={{ textAlign: "center" }}>9</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>Eddie Irvine</td>
                <td style={{ textAlign: "center" }}>Rubens Barrichello</td>
                <td style={{ textAlign: "center" }}>Jordan 195 / Peugeot A10</td>
                <td style={{ textAlign: "center" }}>40</td>
                <td style={{ textAlign: "center" }}>+31.687</td>
                <td style={{ textAlign: "center" }}>throttle</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>3</td>
                <td style={{ textAlign: "center" }}>11</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>Mika Salo</td>
                <td style={{ textAlign: "center" }}>Total Jordan Peugeot</td>
                <td style={{ textAlign: "center" }}>Ferrari 412T2 / Ferrari Type 044/1</td>
                <td style={{ textAlign: "center" }}>38</td>
                <td style={{ textAlign: "center" }}>+1 lap</td>
                <td style={{ textAlign: "center" }}>crash</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>1</td>
                <td style={{ textAlign: "center" }}>5</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>  Jean Alesi</td>
                <td style={{ textAlign: "center" }}>Scuderia Ferrari SpA</td>
                <td style={{ textAlign: "center" }}>Ferrari 412T2 / Ferrari Type 044/1</td>
                <td style={{ textAlign: "center" }}>68</td>
                <td style={{ textAlign: "center" }}>1:44:54.171</td>
                <td style={{ textAlign: "center" }}>11</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>2</td>
                <td style={{ textAlign: "center" }}>9</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>Eddie Irvine</td>
                <td style={{ textAlign: "center" }}>Rubens Barrichello</td>
                <td style={{ textAlign: "center" }}>Jordan 195 / Peugeot A10</td>
                <td style={{ textAlign: "center" }}>40</td>
                <td style={{ textAlign: "center" }}>+31.687</td>
                <td style={{ textAlign: "center" }}>throttle</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>3</td>
                <td style={{ textAlign: "center" }}>11</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>Mika Salo</td>
                <td style={{ textAlign: "center" }}>Total Jordan Peugeot</td>
                <td style={{ textAlign: "center" }}>Ferrari 412T2 / Ferrari Type 044/1</td>
                <td style={{ textAlign: "center" }}>38</td>
                <td style={{ textAlign: "center" }}>+1 lap</td>
                <td style={{ textAlign: "center" }}>crash</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>1</td>
                <td style={{ textAlign: "center" }}>5</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>  Jean Alesi</td>
                <td style={{ textAlign: "center" }}>Scuderia Ferrari SpA</td>
                <td style={{ textAlign: "center" }}>Ferrari 412T2 / Ferrari Type 044/1</td>
                <td style={{ textAlign: "center" }}>68</td>
                <td style={{ textAlign: "center" }}>1:44:54.171</td>
                <td style={{ textAlign: "center" }}>11</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>2</td>
                <td style={{ textAlign: "center" }}>9</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>Eddie Irvine</td>
                <td style={{ textAlign: "center" }}>Rubens Barrichello</td>
                <td style={{ textAlign: "center" }}>Jordan 195 / Peugeot A10</td>
                <td style={{ textAlign: "center" }}>40</td>
                <td style={{ textAlign: "center" }}>+31.687</td>
                <td style={{ textAlign: "center" }}>throttle</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>3</td>
                <td style={{ textAlign: "center" }}>11</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>Mika Salo</td>
                <td style={{ textAlign: "center" }}>Total Jordan Peugeot</td>
                <td style={{ textAlign: "center" }}>Ferrari 412T2 / Ferrari Type 044/1</td>
                <td style={{ textAlign: "center" }}>38</td>
                <td style={{ textAlign: "center" }}>+1 lap</td>
                <td style={{ textAlign: "center" }}>crash</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>1</td>
                <td style={{ textAlign: "center" }}>5</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>  Jean Alesi</td>
                <td style={{ textAlign: "center" }}>Scuderia Ferrari SpA</td>
                <td style={{ textAlign: "center" }}>Ferrari 412T2 / Ferrari Type 044/1</td>
                <td style={{ textAlign: "center" }}>68</td>
                <td style={{ textAlign: "center" }}>1:44:54.171</td>
                <td style={{ textAlign: "center" }}>11</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>2</td>
                <td style={{ textAlign: "center" }}>9</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>Eddie Irvine</td>
                <td style={{ textAlign: "center" }}>Rubens Barrichello</td>
                <td style={{ textAlign: "center" }}>Jordan 195 / Peugeot A10</td>
                <td style={{ textAlign: "center" }}>40</td>
                <td style={{ textAlign: "center" }}>+31.687</td>
                <td style={{ textAlign: "center" }}>throttle</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>3</td>
                <td style={{ textAlign: "center" }}>11</td>
                <td style={{ textAlign: "center" }}>27</td>
                <td style={{ textAlign: "center" }}>Mika Salo</td>
                <td style={{ textAlign: "center" }}>Total Jordan Peugeot</td>
                <td style={{ textAlign: "center" }}>Ferrari 412T2 / Ferrari Type 044/1</td>
                <td style={{ textAlign: "center" }}>38</td>
                <td style={{ textAlign: "center" }}>+1 lap</td>
                <td style={{ textAlign: "center" }}>crash</td>
                <td style={{ textAlign: "center" }}>10</td>
              </tr>

            </tbody>
          </table>
         
          <table className="table  table-dark table-striped table-bordered border-light">
            <thead>
            <tr style={{ width: "100%" }}>
             <th colspan="2" style={{ width: "100%",textAlign: "center",backgroundColor:'#EE1242' }}>Lap leader breakdown:</th>
              </tr>
              <tr>
                <th scope="col" style={{ textAlign: "center" }}>
                LEADER
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                FROM LAP
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                TO LAP
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                # OF LAPS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td style={{ textAlign: "center"}}> Michael Schumacher</td>
                <td style={{ textAlign: "center" }}>1</td>
                <td style={{ textAlign: "center" }}>57</td>
                <td style={{ textAlign: "center" }}>57</td>
                
              </tr>
              <tr >
                <td style={{ textAlign: "center"}}>Jean Alesi</td>
                <td style={{ textAlign: "center" }}>58</td>
                <td style={{ textAlign: "center" }}>68</td>
                <td style={{ textAlign: "center" }}>11</td>
                
              </tr>
            </tbody>
          </table>

          <table className="table  table-dark table-striped table-bordered border-light">
            <thead>
            <tr style={{ width: "100%" }}>
             <th colspan="4" style={{ width: "100%",textAlign: "center",backgroundColor:'#EE1242' }}>Points Standings after this race:</th>
              </tr>
              <tr>
                <th scope="col" style={{ textAlign: "center" }}>
                RANK
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                DRIVER
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                POINTS
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                DIFF
                </th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td style={{ textAlign: "center" }}>1</td>
                <td style={{ textAlign: "center" }}>Michael Schumacher</td>
                <td style={{ textAlign: "center" }}>5</td>
                <td style={{ textAlign: "center" }}></td>
              </tr>
              <tr >
                <td style={{ textAlign: "center" }}>2</td>
                <td style={{ textAlign: "center" }}>Damon Hill</td>
                <td style={{ textAlign: "center" }}>12</td>
                <td style={{ textAlign: "center" }}>83</td>
              </tr>
              <tr >
                <td style={{ textAlign: "center" }}>3</td>
                <td style={{ textAlign: "center" }}>Jean Alesi</td>
                <td style={{ textAlign: "center" }}>61</td>
                <td style={{ textAlign: "center" }}>-31</td>
              </tr>
              <tr >
                <td style={{ textAlign: "center" }}>4</td>
                <td style={{ textAlign: "center" }}>Johnny Herbert</td>
                <td style={{ textAlign: "center" }}>26</td>
                <td style={{ textAlign: "center" }}>-86</td>
              </tr>
              <tr >
                <td style={{ textAlign: "center" }}>5</td>
                <td style={{ textAlign: "center" }}>Michael Schumacher</td>
                <td style={{ textAlign: "center" }}>5</td>
                <td style={{ textAlign: "center" }}>28</td>
              </tr>
              <tr >
                <td style={{ textAlign: "center" }}>2</td>
                <td style={{ textAlign: "center" }}>Damon Hill</td>
                <td style={{ textAlign: "center" }}>45</td>
                <td style={{ textAlign: "center" }}>-12</td>
              </tr>
              <tr >
                <td style={{ textAlign: "center" }}>3</td>
                <td style={{ textAlign: "center" }}>Jean Alesi</td>
                <td style={{ textAlign: "center" }}>61</td>
                <td style={{ textAlign: "center" }}>-31</td>
              </tr>
              <tr >
                <td style={{ textAlign: "center" }}>4</td>
                <td style={{ textAlign: "center" }}>Johnny Herbert</td>
                <td style={{ textAlign: "center" }}>26</td>
                <td style={{ textAlign: "center" }}>-86</td>
              </tr>
              <tr >
                <td style={{ textAlign: "center" }}>5</td>
                <td style={{ textAlign: "center" }}>Michael Schumacher</td>
                <td style={{ textAlign: "center" }}>5</td>
                <td style={{ textAlign: "center" }}>28</td>
              </tr>
             
            </tbody>
          </table>

        </div>
      </section>
    </>
  );
};

export default ModifierTable;
