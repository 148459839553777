
import { configureStore } from '@reduxjs/toolkit';
// import storage from 'redux-persist/lib/storage'
// import { persistReducer } from 'redux-persist';
import apiReducer from './Reducer/Reducer'

// const persistConfig = {
//   key: 'root',
//   version: 1,
//   storage
// }

// const persistedReducer = persistReducer(persistConfig, apiReducer)

const store = configureStore({
  reducer: {
    api: apiReducer,
  },
});


export default store;