/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Riderdatatable.css";
import Banner2 from "../Banner/Banner2";
import Baseurl from "../BaseURL/Baseurl";
import { useDispatch, useSelector } from "react-redux";
import { setRidernamtab, selectRidernamtab } from "../../Redux/Reducer/Reducer";
import userfake from "./user-removebg-preview.png";

function Ridernametable() {
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState(null);
  const [, setError] = useState(null);
  const [key, setKeys] = useState(null);
  const [Additional_data, Setadditional_data] = useState([]);
  const [newData, setDatanew] = useState();
  // const navigate = useNavigate();
  console.log(newData, "usasmaaa");
  const dispatch = useDispatch();
  const ridernamtab = useSelector(selectRidernamtab);

  const { id } = useParams();
  const retrievedData = JSON.parse(localStorage.getItem("RacereName"));
  console.log(retrievedData.item.Driver, "usamaid=======>");
  const Usama = async () => {
    try {
      setLoading(true); // Stop loader once data is fetched
      const response = await axios.get(
        `${Baseurl.baseurl}api/get-race-data/${id}`
      );
      console.log(response.data.additional_data, "response");
      Setadditional_data(response.data.additional_data);
      setDatanew(response.data.additional_data);
      console.log(response, "addintional");
      // setData(response?.data?.csv_data);
      dispatch(setRidernamtab(response?.data?.csv_data));
      setKeys(Object.keys(response?.data?.csv_data?.[0]));
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    Usama();
  }, []);

  console.log(ridernamtab, "usamaconsole");
  console.log(Additional_data, "additional_data");

  console.log(key, "usamareces");
  // const Loader = () => {
  //   return (
  //     <div className="loader-wrapper">
  //       <div className="loader">
  //         <img
  //           className="loaderimg"
  //           src="/assets/images/loader.gif"
  //           alt="loader"
  //         />
  //       </div>
  //     </div>
  //   );
  // };
  
  return (
    <div className="bg-black">
      {/* {loading && <Loader />} */}
      <Banner2
        title="Racing"
        slug="Informations"
        backgroundImage="/assets/images/banner/video-banner-1.jpg"
      />
      {/* <div className="gradient-cardss">
        <div className="cardd">
          <div className="container-cardd bg-green-box">
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p className="card-titles underline-small-h1">
                {retrievedData.item.Driver}
              </p>
              <img
                src={`https://${retrievedData.item.Rider_Country_Img}`} // Updated line
                alt="Country"
                style={{
                  scale: "1.7",
                  marginTop: "10px",
                  border: "solid 1px white",
                }}
                onLoad={() =>
                  console.log(
                    `Image loaded: ${retrievedData.item.Rider_Country_Img}`
                  )
                }
                onError={() =>
                  console.error(
                    `Error loading image: ${retrievedData.item.Rider_Country_Img}`
                  )
                }
              />
               <img
                    src={
                      retrievedData?.rider_image 
                      ||
                       userfake
                      // "/assets/images/bg/martin-truex-jr-212x290.webp"
                    }
                    alt="rider"
                    style={{
                      position: "absolute",
                      top: "-29px",
                      scale: "0.7",
                      right: "-20px",
                      borderRadius: "60px"
                    }}
                    onLoad={() =>
                      console.log(`Image loaded: ${retrievedData?.rider_image}`)
                    }
                    onError={() =>
                      console.error(
                        `Error loading image: ${retrievedData?.rider_image}`
                      )
                    }
                  />
            </div>
            <hr className="color-white" />
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <p className="card-description">
                <h5 style={{ color: "rgb(238, 18, 67)" }}>Born</h5>
                <h6 className="card-titles" style={{ fontSize: "20px" }}>
                  {retrievedData.item.Born}
                </h6>
              </p>
              <p className="card-description">
                <h5 style={{ color: "rgb(238, 18, 67)" }}>Home</h5>
                <h6 className="card-titles" style={{ fontSize: "20px" }}>
                  {retrievedData.item.Home}
                </h6>
              </p>
              <p className="card-description">
                <h5 style={{ color: "rgb(238, 18, 67)" }}>Died</h5>
                <h6 className="card-titles " style={{ fontSize: "20px" }}>
                  {retrievedData.item.Died}
                </h6>
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container">
            <div className="gradient-cardss">
              <div className="carddreward">
                <div className="container-cardd bg-green-box">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <p className="card-titles underline-small-h1">
                      {retrievedData?.item.Driver}
                    </p>

                    <img
                      src={`https://${retrievedData.item.Rider_Country_Img}`} // Updated line
                      alt="Country"
                      style={{
                        scale: "1.7",
                        marginTop: "10px",
                        border: "solid 1px white",
                      }}
                      onLoad={() =>
                        console.log(`Image loaded: ${retrievedData.item.Rider_Country_Img}`)
                      }
                      onError={() =>
                        console.error(
                          `Error loading image: ${retrievedData.item.Rider_Country_Img}`
                        )
                      }
                    />
                  </div>
                  <img
                    src={
                      retrievedData.item.rider_image 
                      ||
                       userfake
                      // "/assets/images/bg/martin-truex-jr-212x290.webp"
                    }
                    alt="rider"
                    style={{
                      position: "absolute",
                      top: "-29px",
                      scale: "0.7",
                      right: "-20px",
                      borderRadius: "60px"
                    }}
                    onLoad={() =>
                      console.log(`Image loaded: ${retrievedData.item.rider_image}`)
                    }
                    onError={() =>
                      console.error(
                        `Error loading image: ${retrievedData.item.rider_image}`
                      )
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <p className="card-description">
                      <h5 style={{ color: "rgb(238, 18, 67)" }}>Born</h5>
                      <h6 className="card-titles" style={{ fontSize: "20px" }}>
                        {retrievedData.item.Born}
                      </h6>
                    </p>
                    <p className="card-description">
                      <h5 style={{ color: "rgb(238, 18, 67)" }}>Home</h5>
                      <h6 className="card-titles" style={{ fontSize: "20px" }}>
                        {retrievedData.item.Home}
                      </h6>
                    </p>
                    <p className="card-description">
                      <h5 style={{ color: "rgb(238, 18, 67)" }}>Died</h5>
                      <h6 className="card-titles " style={{ fontSize: "20px" }}>
                        {retrievedData.item.Died}
                        {console.log(retrievedData, "datad")}
                      </h6>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>  
      <div
        className="container"
        style={{
          border: "solid",
          padding: "10px",
          marginTop: "10px",
          borderRadius: "5px",
        }}
      >
        <div className="tablees-container">
          {ridernamtab && !loading && (
            <table className="table table-dark table-striped table-bordered border-light">
              <thead>
                <tr style={{ width: "auto", textAlign: "center" }}>
                  {key &&
                    key.map((k) => {
                      // Check if the key is "3rdpageuuid" before rendering the th element
                      if (k !== "3rdpageuuid") {
                        return (
                          <th
                            key={k}
                            scope="col"
                            style={{
                              width: "auto",
                              background: "#ee1243",
                              fontWeight: "bold",
                            }}
                          >
                            {k}
                          </th>
                        );
                      }
                      return null; // If key is "3rdpageuuid", don't render anything
                    })}
                </tr>
              </thead>

              <tbody>
                {ridernamtab.map((e, index) => (
                  <tr
                    key={index}
                    style={{ width: "auto", textAlign: "center" }}
                  >
                    {key &&
                      key.map((k) => {
                        // Check if the key is "3rdpageuuid" before rendering the td element
                        if (k !== "3rdpageuuid") {
                          return <td key={k}>{e[k]}</td>;
                        }
                        return null; // If key is "3rdpageuuid", don't render anything
                      })}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="tablees-container">
          {Additional_data && Additional_data.length > 0 && (
            <table className="table table-dark table-striped table-bordered border-light">
              <thead>
                <tr style={{ width: "auto", textAlign: "center" }}>
                  {Object.keys(Additional_data[0]).map((key, index) => (
                    <th
                      key={index}
                      scope="col"
                      style={{
                        width: "auto",
                        background: "#ee1243",
                        fontWeight: "bold",
                      }}
                    >
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {Additional_data?.map((rowData, index) => (
                  <tr
                    key={index}
                    style={{ width: "auto", textAlign: "center" }}
                  >
                    {Object.values(rowData).map((value, key) => (
                      <td key={key}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default Ridernametable;
