import axios from "axios";
import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import Baseurl from "../BaseURL/Baseurl";

function IndyCarSec() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { UUID } = useParams();
  const [data, setData] = useState([]);
  const [tablename, SetTablename] = useState(null);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Stop loader once data is fetched
      try {
        const response = await axios.get(
          `${Baseurl.baseurl}get-indy-second-page/${UUID}`
        );
        console.log(response?.data, "dataIsma");
        setData(response.data.table_data);
        // dispatch(setXfinity(response?.data?.new_champion_data));
        // dispatch(setXfinityTwo(response?.data?.old_champion_data));
        console.log(response.data.table_data, "ddd");
        SetTablename(response.data.table_name);
        setLoading(false); // Stop loader once data is fetched
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    // console.log(xfinitytwo, "xfinitytwo");
    // if (!xfinity.length && !xfinitytwo.length) {
    fetchData();
    // }
  }, [UUID]);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };
  return (
    <section className="about-area pb-60 pt-60 bg-black">
      <div className="container">
        <section className="about-area pb-60 pt-60">
          <div className="tablees-container">
            {loading ? (
              <Loader />
            ) : (
              <>
                <h1 className="underline-small-h1 pb-2 text-white">
                  <span className="color-primary">I</span>ndyCar{" "}
                </h1>
                <h2 className="text-white text-center">{tablename}</h2>
                <table className="table table-dark table-striped table-bordered border-light mt-5">
                  <thead>
                    {data && data.length > 0 && (
                      <tr>
                        {Object.entries(data[0])
                          .filter(
                            ([key]) =>
                              key !== "year_uuid" && key !== "created_at"
                          )
                          .map(([key], i) => (
                            <th
                              style={{
                                textAlign: "center",
                                background: "#ee1243",
                                width: "auto",
                                padding: "5px",
                              }}
                              key={i}
                            >
                              {key}
                            </th>
                          ))}
                      </tr>
                    )}
                  </thead>

                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((row, index) => (
                        <tr key={index}>
                          {Object.entries(row)
                            .filter(
                              ([key]) =>
                                key !== "year_uuid" && key !== "created_at"
                            )
                            .map(([key, value], i) => (
                              <td
                                key={i}
                                style={{
                                  textAlign: "center",
                                  width: "auto",
                                  padding: "5px",
                                }}
                              >
                                {key !== "usama" &&
                                key !== "usama" &&
                                key !== "usama" &&
                                key !== "usama" &&
                                key !== "usama" ? (
                                  <div>{value}</div>
                                ) : (
                                  <Link
                                    onClick={() => {
                                      console.log(row.UUID, "rowuk");
                                      navigate(`/ImsaSec/${row.UUID}`, {
                                        id: row.UUID,
                                      });
                                    }}
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      color: "white",
                                      cursor: "pointer",
                                      textDecoration: "none",
                                    }}
                                    className="colorhover"
                                  >
                                    {value}
                                  </Link>
                                )}
                              </td>
                            ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </section>
      </div>
    </section>
  );
}

export default IndyCarSec;
