/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Baseurl from "../BaseURL/Baseurl";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { setXfinity, selectsetXfinity } from "../../Redux/Reducer/Reducer";
import { setXfinityTwo, selectXfinityTwo } from "../../Redux/Reducer/Reducer";
import { Link, useNavigate } from "react-router-dom";

const CupTable = () => {
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const xfinity = useSelector(selectsetXfinity);
  const xfinitytwo = useSelector(selectXfinityTwo);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Stop loader once data is fetched
      try {
        const response = await axios.get(`${Baseurl.baseurl}get-xfinity/`);
        console.log(response?.data, "dataIsma");
        // setData(response?.data.new_champion_data);
        dispatch(setXfinity(response?.data?.new_champion_data));
        dispatch(setXfinityTwo(response?.data?.old_champion_data));
        console.log(response, "dataIsma");

        setLoading(false); // Stop loader once data is fetched
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    console.log(xfinitytwo, "xfinitytwo");
    if (!xfinity.length && !xfinitytwo.length) {
      fetchData();
    }
  }, [dispatch, xfinity, xfinitytwo]);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="about-area bg-black">
        <div className="container">
          <section className="about-area">
            <div className="tablees-container">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <h1 className="underline-small-h1 pb-2 text-white">
                    <span className="color-primary">X</span>finity{" "}
                  </h1>
                  <table className="table table-dark table-striped table-bordered border-light mt-5">
                    <thead>
                      {xfinity && xfinity.length > 0 && (
                        <tr>
                          {Object.entries(xfinity[0])
                            .filter(
                              ([key]) =>
                                key !== "year_uuid" && key !== "created_at"
                            ) // Exclude 'year_uuid' and 'created_at' keys
                            .map(([key], i) => (
                              <th
                                style={{
                                  textAlign: "center",
                                  background: "#ee1243",
                                  width: "auto",
                                  padding: "5px",
                                }}
                                key={i}
                              >
                                {key}
                              </th>
                            ))}
                        </tr>
                      )}
                    </thead>

                    <tbody>
                      {xfinity &&
                        xfinity.length > 0 &&
                        xfinity.map((row, index) => (
                          <tr key={index}>
                            {Object.entries(row)
                              .filter(
                                ([key]) =>
                                  key !== "year_uuid" && key !== "created_at"
                              ) // Exclude 'year_uuid' and 'created_at' keys
                              .map(([key, value], i) => (
                                <td
                                  key={i}
                                  style={{
                                    textAlign: "center",
                                    width: "auto",
                                    padding: "5px",
                                  }}
                                >
                                  {key !== "Year" &&
                                  key !== "usama" &&
                                  key !== "usama" &&
                                  key !== "usama" &&
                                  key !== "usama" ? (
                                    <div>{value}</div>
                                  ) : (
                                    <a
                                      onClick={() => {
                                        console.log(row.year_uuid, "year_uuid"); // Log the year_uuid
                                        navigate(
                                          `/xfinitySec/${row.year_uuid}`,
                                          {
                                            id: row.year_uuid,
                                          }
                                        );
                                      }}
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "white",
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                      className="colorhover"
                                    >
                                      {value}
                                    </a>
                                  )}
                                </td>
                              ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <hr className="text-white pt-5 mt-5" />
                  <h1 className="underline-small-h1 pb-2 text-white">
                    <span className="color-primary">O</span>ld{" "}
                    <span className="color-primary">X</span>finity{" "}
                  </h1>
                  <table className="table table-dark table-striped table-bordered border-light mt-5">
                    <thead>
                      {xfinitytwo && xfinitytwo.length > 0 && (
                        <tr>
                          {Object.entries(xfinitytwo[0])
                            .filter(
                              ([key]) =>
                                key !== "year_uuid" && key !== "created_at"
                            ) // Exclude 'year_uuid' and 'created_at' keys
                            .map(([key], i) => (
                              <th
                                style={{
                                  textAlign: "center",
                                  background: "#ee1243",
                                  width: "auto",
                                  padding: "5px",
                                }}
                                key={i}
                              >
                                {key}
                              </th>
                            ))}
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {xfinitytwo &&
                        xfinitytwo.length > 0 &&
                        xfinitytwo.map((row, index) => (
                          <tr key={index}>
                            {Object.entries(row)
                              .filter(
                                ([key]) =>
                                  key !== "year_uuid" && key !== "created_at"
                              ) // Exclude 'year_uuid' and 'created_at' keys
                              .map(([key, value], i) => (
                                <td
                                  key={i}
                                  style={{
                                    textAlign: "center",
                                    width: "auto",
                                    padding: "5px",
                                  }}
                                >
                                  {key !== "Year" ? (
                                    // If the key is not 'Champion', you can customize the content here or leave it empty
                                    <div>{value}</div>
                                  ) : (
                                    // If the key is 'Champion', make it clickable
                                    <a
                                      onClick={() => {
                                        console.log(value, "rowuk"); // Log the value, not the entire row
                                        navigate(`/xfinitySecpage/${value}`, {
                                          state: {
                                            /* Adjust this object as needed */
                                          },
                                        });
                                      }}
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "white",
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                      className="colorhover"
                                    >
                                      {value}
                                    </a>
                                  )}
                                </td>
                              ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default CupTable;
