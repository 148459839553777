import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout/Index";
import Home from "./Pages/Home/page";
import Driver from "./Pages/Drivers/page";
import Owner from "./Pages/Owners/page";
import Track from "./Pages/Track/page";
import Truck from "./Pages/Truck/page";
import Arca from "./Pages/Arca/page";
import Cup from "./Pages/Cup/page";
import Xfinity from "./Pages/Xfinity/page";
import Modifier from "./Pages/Modifier/page";
import Imsa from "./Pages/Imsa/page";
import IndyCar from "./Pages/IndyCar/page";
import F1 from "./Pages/F1/page";
import Random from "./Pages/Random/page";
import Riderdatatable from "./Components/DriverTable/Riderdatatable";
import Ridernametable from "./Components/DriverTable/Ridernametable";
import Riderrace from "./Components/DriverTable/Riderrace";
import XfinitySecpage from "./Components/XfinityTable/xfinitySecpage";
import TracksSecpage from "./Components/TrackTable/TracksSecpage";
import CupSecPage from "./Components/CupTable/CupSecPage";
import CupThirdPage from "./Components/CupTable/CupThirdPage";
import CupForPage from "./Components/CupTable/CupForPage";
import F1SecPage from "./Components/F1Table/F1SecPage";
import Ownertwo from "./Components/OwnerTabletwo/page";
import Ownerthree from "./Components/OwnerTablethree/page";
import CupSec from "./Components/CupTable/CupSec";
import XfinitySec from "./Components/XfinityTable/pagesec";
import TruckSec from "./Components/TruckTable/pagesec";
import ArcaSec from "./Components/ArcaTable/pagesec";
import ModifirerSec from "./Components/ModifierTable/pagesec";
import ImsaSec from "./Components/ImsaTable/pagesec";
import IndyCarSec from "./Components/IndyCarTable/pagesec";
import F1Sec from "./Components/F1Table/pagesec";

function App() {

  return (
    <>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/driver" element={<Driver />} />
            <Route exact path="/owner" element={<Owner />} />
            <Route path="/Ownertwo/:uuid" element={<Ownertwo />} />
            <Route path="/Ownerthree/:uuid" element={<Ownerthree />} />
            <Route exact path="/track" element={<Track />} />
            <Route exact path="/cup" element={<Cup />} />
            <Route exact path="/xfinity" element={<Xfinity />} />
            <Route exact path="/truck" element={<Truck />} />
            <Route exact path="/arca" element={<Arca />} />
            <Route exact path="/modifier" element={<Modifier />} />
            <Route exact path="/imsa" element={<Imsa />} />
            <Route exact path="/indycar" element={<IndyCar />} />
            <Route exact path="/f1" element={<F1 />} />
            <Route exact path="/random" element={<Random />} />
            <Route exact path="/driver/:id" element={<Riderdatatable />} />
            <Route exact path="/ridernametable/:id" element={<Ridernametable />} />
            <Route exact path="/riderrace/:id" element={<Riderrace />} />
            <Route exact path="/xfinitySecpage/:id" element={<XfinitySecpage />} />
            <Route exact path="/tracksSecpage/:id" element={<TracksSecpage />} />
            <Route exact path="/CupSecPage/:id" element={<CupSecPage />} />
            <Route exact path="/CupThirdPage/:id" element={<CupThirdPage />} />
            <Route exact path="/CupForPage/:id" element={<CupForPage />} />
            <Route exact path="/F1SecPage/:id" element={<F1SecPage />} />
            <Route exact path="/CupSec/:uuid" element={<CupSec />} />
            <Route exact path="/XfinitySec/:year_uuid" element={<XfinitySec />} />
            <Route exact path="/TruckSec/:year_uuid" element={<TruckSec />} />
            <Route exact path="/ArcaSec/:year_uuid" element={<ArcaSec />} />
            <Route exact path="/ModifirerSec/:year_uuid" element={<ModifirerSec />} />
            <Route exact path="/ImsaSec/:UUID" element={<ImsaSec />} />
            <Route exact path="/IndyCarSec/:UUID" element={<IndyCarSec />} />
            <Route exact path="/F1Sec/:UUID" element={<F1Sec />} />
            
            
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
