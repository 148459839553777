/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Baseurl from "../BaseURL/Baseurl";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setTrack, selectTrack } from "../../Redux/Reducer/Reducer";

const TrackTable = () => {
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [selectedSeries, setSelectedSeries] = useState("");

  const dispatch = useDispatch();
  const track = useSelector(selectTrack);
  console.log(track, "<==checking");
  const navigate = useNavigate();

  const seriesNames = [
    "All Series",
    "A1 Grand Prix",
    "ABB Formula E",
    "ACT Late Model Tour",
    "American Le Mans Series",
    "ARCA Menards Series",
    "ARCA Menards Series",
    "ARCA Menards Series East",
    "ARCA Menards Series West",
    "ASA National Tour",
    "Camping World Truck Series",
    "CASCAR Super Series",
    "CASCAR East Series",
    "CASCAR West Series",
    "CARS Pro Late Model Tour",
    "CARS Late Model Stock Tour",
    "CART / CCWS",
    "Convertible Division",
    "FedEx Challenge Series",
    "Formula One",
    "Formula Two",
    "Grand-Am Series",
    "Grand National East",
    "Indy Lights",
    "IROC",
    "K&N Pro Series East",
    "K&N Pro Series West",
    "Midwest Series",
    "NASCAR Cup Series",
    "NASCAR North",
    "N.A. Touring Car Series",
    "Northwest Series",
    "NTT IndyCar Series",
    "Peak Mexico Series",
    "Pinty's Series",
    "Rev-Oil Pro Cup",
    "Southeast Series",
    "Southwest Series",
    "USAC Champ Car",
    "NTT IndyCar Series",
    "Repco Supercars Championship",
    "Weathertech SportsCars",
    "EuroNASCAR Pro",
    "EuroNASCAR2",
    "Club Challenge",
    "Whelen Modified Tour",
    "Whelen Southern Modified Tour",
    "Xfinity Series",
  ];

  const handleSeriesChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedSeries(selectedValue);
    fetchData(selectedValue);
  };

  useEffect(() => {
    console.log(track, "track");
    if (!track.length) {
      fetchData("");
    }
  }, [dispatch]);

  const fetchData = async (series) => {
    setLoading(true); // Stop loader once data is fetched
    try {
      const response = await axios.get(
        `${Baseurl.baseurl}get-tracks?series_name=${series}`
      );
      console.log(response?.data?.tracks_data, "dataTrux");
      dispatch(setTrack(response?.data?.tracks_data));
      // setData(response?.data?.tracks_data);
      setLoading(false); // Stop loader once data is fetched
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <section className="about-area pb-60 pt-60 bg-black">
        <div className="container">
          <h2
            className="pb-1 text-white"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            NASCAR CUP SERIES STATS, DRIVER, TRACK, OWNER, MANUFACTURER STATS
          </h2>

          <div className="row">
            <div className="col-xl-12">
              <div
                className="banner-filter-form mt-40"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="card p-30 shadow-lg bg-dark">
                  <form action="#">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-lg-12">
                        <div className="row">
                          <Form.Group className="mb-1">
                            <Form.Label className="field-title color-light">
                              Show only tracks from the following series:
                            </Form.Label>
                            <Form.Select
                              value={selectedSeries}
                              onChange={handleSeriesChange}
                            >
                              {seriesNames.map((series, index) => (
                                <option key={index} value={series}>
                                  {series}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="tablees-container"> */}
          <div className="tablees-container">
            {loading ? (
              <Loader />
            ) : (
              <table className="table table-dark table-striped table-bordered border-light">
                <thead>
                  {track && track.length > 0 && (
                    <tr>
                      {Object.entries(track[0])
                        .filter(([key]) => key !== "UUID") // Exclude key "UUID" from headers
                        .map(([key], i) => (
                          <th
                            style={{
                              textAlign: "center",
                              background: "#ee1243",
                              width: "auto",
                              padding: "5px",
                            }}
                            key={i}
                          >
                            {key.replaceAll("_", " ")}
                          </th>
                        ))}
                    </tr>
                  )}
                </thead>
                <tbody>
                  {track &&
                    track.length > 0 &&
                    track?.map((row, index) => (
                      <tr key={index}>
                        {Object.entries(row)
                          .filter(([key]) => key !== "UUID") // Exclude key-value pair with key "UUID"
                          .map(([key, value], i) => (
                            <React.Fragment key={i}>
                              {key === "Country" ? (
                                <td
                                  style={{
                                    textAlign: "center",
                                    width: "auto",
                                    padding: "5px",
                                  }}
                                >
                                  <img
                                    src={value}
                                    alt={value}
                                    style={{
                                      maxWidth: "50px",
                                      maxHeight: "50px",
                                    }}
                                  />
                                </td>
                              ) : (
                                <td
                                  style={{
                                    textAlign: "center",
                                    width: "auto",
                                    padding: "5px",
                                  }}
                                >
                                  {key !== "usama" ? (
                                    // If the key is not 'Champion', you can customize the content here or leave it empty
                                    <div>{value}</div>
                                  ) : (
                                    // If the key is 'Champion', make it clickable
                                    <a
                                      onClick={() => {
                                        navigate(`/tracksSecpage/${row.UUID}`, {
                                          state: { row },
                                        });
                                        console.log("Champion", row?.UUID);
                                      }}
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "white",
                                        cursor: "pointer",
                                        textDecoration: "none",
                                        display: "block", // Set display to block to make each value appear on a new line
                                      }}
                                      className="colorhover"
                                    >
                                      {console.log(value, "valueuk")}
                                      {typeof value === "object" ? (
                                        Object.values(value).map(
                                          (val, index) => (
                                            <div key={index}>{val}</div>
                                          )
                                        )
                                      ) : (
                                        <div>{value}</div>
                                      )}
                                    </a>
                                  )}
                                </td>
                              )}
                            </React.Fragment>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TrackTable;
