/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import Baseurl from "../BaseURL/Baseurl";
import { useDispatch, useSelector } from "react-redux";
import { setSerieskey, selectSerieskey } from "../../Redux/Reducer/Reducer";

const DriverSec1 = () => {
  // const [data, setdata] = useState();

  const dispatch = useDispatch();
  const serieskey = useSelector(selectSerieskey);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Stop loader once data is fetched
      try {
        const response2 = await axios.get(`${Baseurl.baseurl}get-data/`);
        console.log(response2.data, "response2");
        // setdata(response2?.data);
        dispatch(setSerieskey(response2?.data));
        setLoading(false); // Stop loader once data is fetched
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    console.log(serieskey, "datauk");

    fetchData();
  }, []);

  
  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  const divStyle = {
    backgroundImage: 'url("/assets/images/bg/series.jpg")',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    // height: '100vh',
    // Add any other styles you want for the container
  };

  return (
    <>
      <section style={divStyle} className="about-area pt-100 pb-60">
        <div className="container">
          <div className="row gx-xl-5 align-items-center">
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="image mb-40">
                <img
                  className="lazyload"
                  src="/assets/images/about.png"
                  alt="driver info"
                />
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-up">
              <div className="content-title mb-40">
                <span className="subtitle mb-10 color-primary"><b>Series Key</b></span>
                <h2 className="title mb-25 color-primary">
                  Active Driver Listing
                  <span>.</span>
                </h2>
                {loading ? (
              <Loader />
            ) : (
                <div
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  {serieskey?.map((item, index) => (
                    <div key={index} >
                      <p className="text-white underline-small-h1">{item?.SERIES}</p>
                      <p className="text-white" style={{ fontWeight: "bold" }}>{item?.SERIESKEY}</p>
                    </div>
                  ))}
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DriverSec1;
