/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Section5 = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: 'url("/assets/images/image10.png")',
          backgroundSize: "cover",
        }}
      >
        <h2
          style={{ color: "#ee1243", textAlign: "center" }}
          className="pt-3 pb-3"
        >
          New on the site:
        </h2>
        <div className="container">
          <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <h4 className="text-light texmobil">
              [IMSA] "Motul Petit Le Mans" (10/24/23)
            </h4>
            <p className="banner2-para">
              <span>
                <img
                  className="h-100"
                  src="/assets/images/in_check-circled-outline.png"
                  alt="Image"
                />{" "}
              </span>
              Last race: "4EVER 4010 Presented by Mobil 1"
            </p>
            <p className="banner2-para">
              <span>
                <img
                  className="h-100"
                  src="/assets/images/in_check-circled-outline.png"
                  alt="Image"
                />{" "}
              </span>
              [Cup] "Xfinity 500" Entry List (10/24/23)
            </p>
            <p className="banner2-para">
              <span>
                <img
                  className="h-100"
                  src="/assets/images/in_check-circled-outline.png"
                  alt="Image"
                />{" "}
              </span>
              [F1] "Lenovo United States Grand Prix" (10/23/23)
            </p>
            <p className="banner2-para">
              <span>
                <img
                  className="h-100"
                  src="/assets/images/in_check-circled-outline.png"
                  alt="Image"
                />{" "}
              </span>
              [Truck] "Baptist Health 200" (10/21/23)
            </p>
            <p className="banner2-para">
              <span>
                <img
                  className="h-100"
                  src="/assets/images/in_check-circled-outline.png"
                  alt="Image"
                />{" "}
              </span>
              [Mods] "World Series 150" (10/09/23)
            </p>
            <p className="banner2-para">
              <span>
                <img
                  className="h-100"
                  src="/assets/images/in_check-circled-outline.png"
                  alt="Image"
                />{" "}
              </span>
              [ARCA East] "Bush's Beans 200" (09/14/23)
            </p>
            <p className="banner2-para">
              <span>
                <img
                  className="h-100"
                  src="/assets/images/in_check-circled-outline.png"
                  alt="Image"
                />{" "}
              </span>
              [IndyCar] "Firestone Grand Prix of Monterey" (09/12/23)
            </p>
          </div>
        </div> 
        </div>
       
      </section>
    </>
  );
};

export default Section5;
