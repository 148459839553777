/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import Baseurl from "../BaseURL/Baseurl";
import { useDispatch, useSelector } from "react-redux";
import { setF1, selectF1 } from "../../Redux/Reducer/Reducer";
import { useNavigate } from "react-router-dom";

const ModifierTable = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const f1 = useSelector(selectF1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get(`${Baseurl.baseurl}get-f1-series/`);
        console.log(response?.data, "dataIsma");
        dispatch(setF1(response?.data?.data));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (!f1.length) {
      fetchData();
    }
  }, [dispatch, f1]);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-black">
        <p className="text-center color-primary">Error: {error}</p>
      </div>
    );
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="about-area pb-60 pt-60 bg-black">
        <div className="container">
          <section className="about-area pb-60 pt-60">
            <div className="tablees-container">
              {loading ? (
                <Loader />
              ) : (
                <table className="table table-dark table-striped table-bordered border-light">
                  <thead>
                    {f1 && f1.length > 0 && (
                      <tr>
                        {Object.entries(f1[0]).map(([key], i) => {
                          if (!key.includes("UUID") && key !== "created_at") {
                            return (
                              <th
                                style={{
                                  textAlign: "center",
                                  background: "#EE1242",
                                  width: "auto",
                                  padding: "5px",
                                }}
                                key={i}
                              >
                                {key.replaceAll("_", " ")}
                              </th>
                            );
                          }
                          return null;
                        })}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {f1 &&
                      f1.length > 0 &&
                      f1.map((row, index) => (
                        <tr key={index}>
                          {Object.entries(row).map(([key, value], i) => {
                            if (!key.includes("UUID") && key !== "created_at") {
                              return (
                                <td
                                  key={i}
                                  style={{
                                    textAlign: "center",
                                    width: "auto",
                                    padding: "5px",
                                  }}
                                >
                                  {key !== "Year" ? (
                                    <div>{value}</div>
                                  ) : (
                                    <a
                                      onClick={() => {
                                        const uuid = row.UUID; // Assuming UUID is stored in the row object
                                        console.log(uuid, "rowUUID");
                                        navigate(`/F1Sec/${uuid}`, {
                                          state: {
                                            /* Adjust this object as needed */
                                          },
                                        });
                                      }}
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "white",
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                      className="colorhover"
                                    >
                                      {typeof value === "object"
                                        ? value[0]
                                        : value}
                                    </a>
                                  )}
                                </td>
                              );
                            }
                            return null;
                          })}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default ModifierTable;
