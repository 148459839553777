/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import Baseurl from "../BaseURL/Baseurl";
import { useDispatch, useSelector } from "react-redux";
import { setIndycar, selectIndycar } from "../../Redux/Reducer/Reducer";
import { useNavigate } from "react-router-dom";

const ModifierTable = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const indycar = useSelector(selectIndycar);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Baseurl.baseurl}get-Indy-Car/`);
        console.log(response?.data, "dataIndycar");
        dispatch(setIndycar(response?.data?.data));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (!indycar.length) {
      fetchData();
    }
  }, [dispatch, indycar]);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const divStyle = {
    backgroundImage: 'url("/assets/images/bg/1.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <>
      <section style={divStyle} className="about-area pb-60 pt-60">
        <div className="container">
          <section className="about-area pb-60 pt-60">
            <div className="tablees-container">
              <table className="table table-dark table-striped table-bordered border-light">
                <thead>
                  {indycar && indycar.length > 0 && (
                    <tr>
                      {Object.entries(indycar[0]).map(([key], i) => {
                        if (!key.includes("UUID")) {
                          return (
                            <th
                              style={{
                                textAlign: "center",
                                background: "#EE1242",
                                width: "auto",
                                padding: "5px",
                              }}
                              key={i}
                            >
                              {key.replaceAll("_", " ")}
                            </th>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  )}
                </thead>
                <tbody>
                  {indycar &&
                    indycar.length > 0 &&
                    indycar.map((row, index) => (
                      <tr key={index}>
                        {Object.entries(row).map(([key, value], i) => {
                          if (!key.includes("UUID")) {
                            return (
                              <td
                                key={i}
                                style={{
                                  textAlign: "center",
                                  width: "auto",
                                  padding: "5px",
                                }}
                              >
                                {key !== "Year" ? (
                                  typeof value === "object" ? (
                                    Object.values(value).map((val, index) => (
                                      <div key={index}>{val}</div>
                                    ))
                                  ) : (
                                    <div>{value}</div>
                                  )
                                ) : (
                                  <a
                                    onClick={() => {
                                      const uuid = row.UUID; // Assuming UUID is stored in the row object
                                      console.log(uuid, "rowUUID");
                                      navigate(`/IndyCarSec/${uuid}`, {
                                        state: {
                                          /* Adjust this object as needed */
                                        },
                                      });
                                    }}
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      color: "white",
                                      cursor: "pointer",
                                      textDecoration: "none",
                                      display: "block",
                                    }}
                                    className="colorhover"
                                  >
                                    {typeof value === "object" ? (
                                      Object.values(value).map(
                                        (val, index) => (
                                          <div key={index}>{val}</div>
                                        )
                                      )
                                    ) : (
                                      <div>{value}</div>
                                    )}
                                  </a>
                                )}
                              </td>
                            );
                          }
                          return null;
                        })}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default ModifierTable;
