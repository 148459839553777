import React from "react";
import "./Bannerdriv.css";

const Bannerdriv = () => {
  return (
    <>
      <div class="banner">
        <div class="border"> </div>
        <h2>Racing Reference</h2>
        <p>
          by Wes <span>RacingRef</span>
        </p>
        <div class="border border-bottom"> </div>
      </div>
    </>
  );
};

export default Bannerdriv;
