/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Riderdatatable.css";
import Baseurl from "../BaseURL/Baseurl";
import Bannerdriv from "../Banner/Bannerdriv";
import userfake from "./user-removebg-preview.png";

function Riderdatatable() {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [datad, setdData] = useState([]);
  const { id } = useParams();
  const { state } = useLocation();
  console.log(state.item, "usamaid....");
  const itemdata = state;

  localStorage.setItem("RacereName", JSON.stringify(itemdata));
  const Usama = async () => {
    setLoading(true); // Stop loader once data is fetched
    try {
      const response = await axios.get(
        `${Baseurl.baseurl}get-rider-data/${id}`
      );
      setLoading(false);
      setData(response?.data?.all_data);
      console.log(response, "usamakkk");
      setdData(response?.data?.driver_info);
      console.log(datad, "usamadata");
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    Usama();
    window.scrollTo(0, 0);
  }, []);

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Bannerdriv title="Driver" />
      <div className="bg-dark">
        <div className="container">
          {datad?.Driver && (
            <div className="gradient-cardss">
              <div className="carddreward">
                <div className="container-cardd bg-green-box">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <p className="card-titles underline-small-h1">
                      {datad?.Driver}
                    </p>

                    <img
                      src={`https://${datad?.Rider_Country_Img}`} // Updated line
                      alt="Country"
                      style={{
                        scale: "1.7",
                        marginTop: "10px",
                        border: "solid 1px white",
                      }}
                      onLoad={() =>
                        console.log(`Image loaded: ${datad?.Rider_Country_Img}`)
                      }
                      onError={() =>
                        console.error(
                          `Error loading image: ${datad?.Rider_Country_Img}`
                        )
                      }
                    />
                  </div>
                  <img
                    src={
                      datad?.rider_image 
                      ||
                       userfake
                      // "/assets/images/bg/martin-truex-jr-212x290.webp"
                    }
                    alt="rider"
                    style={{
                      position: "absolute",
                      top: "-29px",
                      scale: "0.7",
                      right: "-20px",
                      borderRadius: "60px"
                    }}
                    onLoad={() =>
                      console.log(`Image loaded: ${datad?.rider_image}`)
                    }
                    onError={() =>
                      console.error(
                        `Error loading image: ${datad?.rider_image}`
                      )
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <p className="card-description">
                      <h5 style={{ color: "rgb(238, 18, 67)" }}>Born</h5>
                      <h6 className="card-titles" style={{ fontSize: "20px" }}>
                        {datad?.Born}
                      </h6>
                    </p>
                    <p className="card-description">
                      <h5 style={{ color: "rgb(238, 18, 67)" }}>Home</h5>
                      <h6 className="card-titles" style={{ fontSize: "20px" }}>
                        {datad?.Home}
                      </h6>
                    </p>
                    <p className="card-description">
                      <h5 style={{ color: "rgb(238, 18, 67)" }}>Died</h5>
                      <h6 className="card-titles " style={{ fontSize: "20px" }}>
                        {datad?.Died}
                        {console.log(datad, "datad")}
                      </h6>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="container"
            style={{
              border: "solid",
              borderRadius: "5px",
            }}
          >
            {data?.map((e, index) => (
              <>
                <p className="tablnename text-center mt-3">
                  {e?.table_name
                    .replaceAll("_", " ")
                    .replace("TABLE NAME ", "")}
                </p>

                <div className="tablees-container mt-3">
                  {loading ? (
                    <Loader />
                  ) : (
                    <table className="table  table-dark table-striped table-bordered border-light">
                      <thead>
                        <tr
                          key={index}
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          {e?.csv_data[0] &&
                            Object.keys(e.csv_data[0])
                              .slice(0, -2) // Exclude the last two keys
                              .map((k) => (
                                <th
                                  key={k}
                                  scope="col"
                                  style={{
                                    width: "auto",
                                    background: "#ee1243",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {k}
                                </th>
                              ))}
                        </tr>
                      </thead>
                      <tbody>
                        {e?.csv_data?.map((item, rowIndex, array) => (
                          <tr
                            key={rowIndex}
                            style={{
                              width: "auto",
                              textAlign: "center",
                              fontWeight:
                                rowIndex === array.length - 1
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            {Object.entries(item)
                              .slice(0, -2)
                              .map(([key, value], index) => (
                                <td key={index}>
                                  {console.log(value, "value")}
                                  {key === "Races" || key === "Race" ? (
                                    <span
                                      onClick={() =>
                                        (item.Races || item.Race) &&
                                        navigate(
                                          item.Races || item.Race
                                            ? item.Races
                                              ? `/ridernametable/${item.races_uuid}`
                                              : `/riderrace/${item.race_uuid}`
                                            : ""
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        cursor:
                                          item.Races || item.Race
                                            ? "pointer"
                                            : "default",
                                      }}
                                      className="colorhover"
                                    >
                                      {value}
                                    </span>
                                  ) : (
                                    value
                                  )}
                                </td>
                              ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Riderdatatable;
