import { createSlice } from '@reduxjs/toolkit';

const apiReducer = createSlice({
  name: "api",
  initialState: {
    drivers: [],
    track: [],
    truck: [],
    cup: [],
    owner: [],
    xfinity: [],
    arca: [],
    modifier: [],
    imsa: [],
    indycar: [],
    f1: [],
    // ---andar pages----
    ridernamtab: [],
    xfinitytwo:[],

  },
  reducers: {
    setDrivers: (state, action) => {
      state.drivers = action.payload;
    },
    // addDriver: (state, action) => {
    //   state.drivers.push(action.payload);
    // },
    // deleteDriver: (state, action) => {
    //   state.drivers = state.drivers.filter((driver) => driver._id !== action.payload);
    // },


    setTrack: (state, action) => {
      state.track = action.payload;
    },

    setTruck: (state, action) => {
      state.truck = action.payload;
    },

    setCup: (state, action) => {
      state.cup = action.payload;
    }
    ,

    setOwner: (state, action) => {
      state.owner = action.payload;
    }
    ,
    // addOwner: (state, action) => {
    //   state.owner.push(action.payload);
    // },
    // UpdateOwner: (state, action) => {
    //   state.owner.put(action.payload);
    // },
    // deleteOwner: (state, action) => {
    //   state.owner = state.owner.filter((value) => value.UUID !== action.payload);
    // },
    setXfinityTwo: (state, action) => {
      state.xfinitytwo = action.payload;
    }
    ,

    setXfinity: (state, action) => {
      state.xfinity = action.payload;
    }
    ,
    setArca: (state, action) => {
      state.arca = action.payload;
    }
    ,
    setModifier: (state, action) => {
      state.modifier = action.payload;
    }
    ,
    setImsa: (state, action) => {
      state.imsa = action.payload;
    }
    ,
    setIndycar: (state, action) => {
      state.indycar = action.payload;
    }
    ,
    setF1: (state, action) => {
      state.f1 = action.payload;
    }
    // --------andar pages--------
    ,
    setRidernamtab: (state, action) => {
      state.ridernamtab = action.payload;
    }
    ,
    setSerieskey: (state, action) => {
      state.serieskey = action.payload;
    }
  },
});

export const { setDrivers, addDriver, deleteDriver, setTrack, setTruck, setCup, setOwner, UpdateOwner, deleteOwner, setXfinity, setArca, setModifier, setImsa, setIndycar, setF1, setRidernamtab, setSerieskey, setXfinityTwo} = apiReducer.actions;

export const selectDrivers = (state) => state.api.drivers;
export const selectTrack = (state) => state.api.track;
export const selectCup = (state) => state.api.cup;
export const selectOwner = (state) => state.api.owner;
export const selectsetXfinity = (state) => state.api.xfinity;
export const selectTruck = (state) => state.api.truck;
export const selectArca = (state) => state.api.arca;
export const selectModifier = (state) => state.api.modifier;
export const selectImsa = (state) => state.api.imsa;
export const selectIndycar = (state) => state.api.indycar;
export const selectF1 = (state) => state.api.f1;

// -----------andar pages-----------
export const selectRidernamtab = (state) => state.api.ridernamtab;
export const selectSerieskey = (state) => state.api.serieskey;
export const selectXfinityTwo = (state) => state.api.xfinitytwo;



export default apiReducer.reducer;