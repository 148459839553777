/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import Baseurl from "../BaseURL/Baseurl";
import { useDispatch, useSelector } from "react-redux";
import { setOwner, selectOwner } from "../../Redux/Reducer/Reducer";
import { Link, useNavigate } from "react-router-dom";

const TrackTable = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const owner = useSelector(selectOwner);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Baseurl.baseurl}get-owners`);
        console.log(response.data, "dataCup");
        dispatch(setOwner(response?.data?.data));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (!owner.length) {
      fetchData();
    }
  }, [dispatch, owner]);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper bg-dark">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  const divStyle = {
    background: "black",
  };

  return (
    <>
      <div style={divStyle}>
        <div
          className="container"
          style={{
            border: "solid",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <h3
            style={{
              width: "15%",
              background: "#EE1242",
              textAlign: "center",
              borderRadius: "20px",
              color: "white",
            }}
          >
            All Owner's
          </h3>
          <div className="tablees-container">
            {loading ? (
              <Loader />
            ) : (
              <div className="row row-cols-4 g-2">
                {owner &&
                  owner.length > 0 &&
                  owner.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      {Object.entries(row)
                        .filter(
                          ([key]) =>
                            !key.includes("_id") &&
                            !key.includes("created_at") &&
                            !key.includes("UUID")
                        )
                        .map(([key, value], columnIndex) => (
                          <div
                            key={columnIndex}
                            className="col-3"
                            onClick={() =>
                              navigate(`/Ownertwo/${row.UUID}`, {
                                id: row.UUID,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="p-2 text-center text-white bg-dark"
                              style={{
                                border: "solid 2px #EE1242",
                                fontWeight: "bold",
                              }}
                            >
                              {typeof value === "object" ? (
                                Object.values(value).map((val, index) => (
                                  <div key={index}>{val}</div>
                                ))
                              ) : (
                                <div>{value}</div>
                              )}
                            </div>
                          </div>
                        ))}
                    </React.Fragment>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackTable;
