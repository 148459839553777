/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../DriverTable/Riderdatatable.css";
import Banner2 from "../Banner/Banner2";
import Baseurl from "../BaseURL/Baseurl";
import "../DriverTable/page.css";

function CupThirdPage() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [, setError] = useState(null);
  const [key, setKeys] = useState(null);

  const { id } = useParams();
  // const { state } = useLocation();
  const retrievedData = JSON.parse(localStorage.getItem("RacereName"));
  console.log(retrievedData.item.Driver, "usamaid=======>");
  const Usama = async () => {
    try {
      const response = await axios.get(
        `${Baseurl.baseurl}api/get-race-data/${id}`
      );
      setData(response?.data?.csv_data);
      setKeys(Object.keys(response?.data?.csv_data?.[0]));
      console.log(Object.keys(response), "usamaconsole");
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    Usama();
    window.scrollTo(0, 0);
  }, []);

  console.log(data?.[0]?.Driver, "indexuk");
  useEffect(() => {
    const fakeAsyncAction = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulating a 2-second delay
      setLoading(false);
    };

    fakeAsyncAction();
  }, []);
  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };
  return (
    <div className="bg-black">
      {/* {loading && <Loader />} */}
      <Banner2
        title="Racing"
        slug="Informations"
        backgroundImage="/assets/images/banner/video-banner-1.jpg"
      />
      {/* <div className="container">
        <div className="row">
          <div className="col-md-3" style={{ width: "auto" }}>
            <h2 style={{ fontSize: "auto" }}>{retrievedData.item.Driver}</h2>
          </div>
          <div className="col-md-3">
            <img
              src={`https://${retrievedData.item.Rider_Country_Img}`} // Updated line
              alt="Country"
              style={{ scale: "1.5", marginTop: "15px" }}
              onLoad={() =>
                console.log(
                  `Image loaded: ${retrievedData.item.Rider_Country_Img}`
                )
              }
              onError={() =>
                console.error(
                  `Error loading image: ${retrievedData.item.Rider_Country_Img}`
                )
              }
            />
          </div>
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-md-3 d-flex">
            &nbsp;<h5 style={{ color: "rgb(238, 18, 67)" }}>Born:</h5>
            <h5 className="text-center" style={{ fontSize: "auto" }}>
              &nbsp;{retrievedData.item.Born}
            </h5>
          </div>
          <div className="col-md-9"></div>
        </div>
        <div className="row">
          <div className="col-md-3 d-flex">
            &nbsp;<h5 style={{ color: "rgb(238, 18, 67)" }}>Home:</h5>
            <h5 className="text-center" style={{ fontSize: "16px" }}>
              &nbsp;{retrievedData.item.Home}
            </h5>
          </div>
          <div className="col-md-9"></div>
        </div>
        <div className="row">
          <div className="col-md-3 d-flex">
            &nbsp;<h5 style={{ color: "rgb(238, 18, 67)" }}>Died: </h5>
            <h5 className="text-center" style={{ fontSize: "auto" }}>
              &nbsp;{retrievedData.item.Died}
            </h5>
          </div>
          <div className="col-md-9"></div>
        </div>
        <hr />
      </div> */}

      <div className="gradient-cardss">
          <div className="cardd">
            <div className="container-cardd bg-green-box">
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <p className="card-titles underline-small-h1">
                  {retrievedData.item.Driver}
                </p>
                <img
                  src={`https://${retrievedData.item.Rider_Country_Img}`} // Updated line
                  alt="Country"
                  style={{
                    scale: "1.7",
                    marginTop: "10px",
                    border: "solid 1px white",
                  }}
                  onLoad={() =>
                    console.log(`Image loaded: ${retrievedData.item.Rider_Country_Img}`)
                  }
                  onError={() =>
                    console.error(
                      `Error loading image: ${retrievedData.item.Rider_Country_Img}`
                    )
                  }
                />
              </div>
              <hr className="color-white" />
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <p className="card-description">
                  <h5 style={{ color: "rgb(238, 18, 67)" }}>Born</h5>
                  <h6 className="card-titles" style={{ fontSize: "20px" }}>
                    {retrievedData.item.Born}
                  </h6>
                </p>
                <p className="card-description">
                  <h5 style={{ color: "rgb(238, 18, 67)" }}>Home</h5>
                  <h6 className="card-titles" style={{ fontSize: "20px" }}>
                    {retrievedData.item.Home}
                  </h6>
                </p>
                <p className="card-description">
                  <h5 style={{ color: "rgb(238, 18, 67)" }}>Died</h5>
                  <h6 className="card-titles " style={{ fontSize: "20px" }}>
                    {retrievedData.item.Died}
                  </h6>
                </p>
              </div>
            </div>
          </div>
        </div>
      
      <div
        className="container"
        style={{
          border: "solid",
          padding: "10px",
          marginTop: "10px",
          borderRadius: "5px",
        }}
      >
        <>
          <div className="tablees-container">
            {loading ? (
              <Loader />
            ) : (
              <table className="table  table-dark table-striped table-bordered border-light">
                <thead>
                  <tr style={{ width: "auto", textAlign: "center" }}>
                    {key &&
                      key
                        .filter((k) => k !== "3rdpageuuid") // Exclude the specific value
                        .map((k) => (
                          <th
                            key={k}
                            scope="col"
                            style={{
                              width: "auto",
                              background: "#ee1243",
                              fontWeight: "bold",
                            }}
                          >
                            {k}
                          </th>
                        ))}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item) => (
                    <tr key={item.id} style={{ width: "100%" }}>
                      <th style={{ textAlign: "center" }}>{item.Pos}</th>
                      <th style={{ textAlign: "center" }}>{item.St}</th>
                      <th style={{ textAlign: "center" }}>{item["#"]}</th>
                      <th>
                        {console.log(item.Driver[0][1], "usamaimg")}
                        <div style={{ flexWrap: "nowrap" }}>
                          &nbsp; &nbsp;
                          <img
                            src={`https://${item.Driver[0][0]}`}
                            alt="Country"
                          />
                          &nbsp; &nbsp;
                          <span>{item.Driver[0][1]}</span>
                        </div>
                      </th>

                      <th style={{ textAlign: "center" }}>
                        {item?.["Sponsor / Owner"]}
                      </th>
                      <th style={{ textAlign: "center" }}>{item.Car}</th>
                      <th style={{ textAlign: "center" }}>{item.Laps}</th>
                      <th style={{ textAlign: "center" }}>{item.Money}</th>
                      <th
                        style={{
                          textAlign: "center",
                          color: item.Status === "running" ? "green" : "red",
                        }}
                      >
                        {item.Status}
                      </th>

                      <th style={{ textAlign: "center" }}>{item.Led}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <br />
        </>
      </div>
    </div>
  );
}

export default CupThirdPage;
