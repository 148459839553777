import React, { useEffect } from "react";
import DriverSec1 from "../../Components/DriverSec1/page";
import DriverTable from "../../Components/DriverTable/page";

const Drivers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Banner2 title="Welcome To Drivers"/> */}
      <div
        class="page-title-area pb-200"
        style={{
          height: "710px",
          width: "100%",
          backgroundImage: 'url("/assets/images/banner/action-banner-1.jpg")',
        }}
      >
        <div class="container">
          <div class="content-slider">
            <h1
              className="underline-small-h1"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Welcome To Drivers
            </h1>
            <br />
            <h4
              className="underline-small-h4"
              data-aos="fade-down"
              data-aos-delay="100"
            >
              RACING List
            </h4>
            <br />
            <h1
              className="underline-small-h1"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Records
            </h1>
          </div>
        </div>
      </div>

      <DriverSec1 />
      {/* <DriverSec3/> */}
      <DriverTable />
    </>
  );
};

export default Drivers;
