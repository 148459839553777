import React from 'react'

const Banner = ({ title, slug }) => {
  return (
    <>
      <div class="page-title-area pb-100 bg-img" >
        <div class="container">
          <div class="content-slider d-flex justify-content-center align-items-center flex-column">
            <h1 className='underline-small-h1'>{title}</h1>
            <h4 className='underline-small-h4'>{slug}</h4>

            <div className="col-xl-8">
              <div className="banner-filter-form mt-40" data-aos="fade-up" data-aos-delay="100">
                <div className="card p-30 shadow-lg" style={{backgroundColor:'#00000087'}}>
                  <form action="#">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                              <label for="location" className="font-md text-light">Driver Search</label>
                              <input type="text" className="form-control" placeholder="Driver" />
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="col-lg-8">
                        <button type="button" className="btn btn-theme color-white w-100 mt-2">
                          <span className="d-inline-block">Let's go</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default Banner