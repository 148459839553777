/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import Baseurl from "../BaseURL/Baseurl";
import { useDispatch, useSelector } from "react-redux";
import { setImsa, selectImsa } from "../../Redux/Reducer/Reducer";
import { useNavigate } from "react-router-dom";
import "./imsa.css";

const ModifierTable = () => {
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const imsa = useSelector(selectImsa);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Stop loader once data is fetched
      try {
        const response = await axios.get(`${Baseurl.baseurl}get-imsa-stats/`);
        console.log(response?.data, "dataIsma");
        // setData(response?.data);
        dispatch(setImsa(response?.data?.data));
        setLoading(false); // Stop loader once data is fetched
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    console.log(imsa, "imsa");
    if (!imsa.length) {
      fetchData();
    }
  }, [dispatch, imsa]);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  const divStyle = {
    backgroundImage: 'url("/assets/images/bg/1.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    // height: '100vh',
    // Add any other styles you want for the container
  };

  return (
    <>
      <section style={divStyle} className="about-area pb-60 pt-60">
        <div className="container">
          <section className="about-area pb-60 pt-60">
            <div className="tablees-container">
              {loading ? (
                <Loader />
              ) : (
                <table className="table table-dark table-striped table-bordered border-light">
                  <thead>
                    {imsa && imsa.length > 0 && (
                      <tr>
                        {Object.entries(imsa[0]).map(([key], i) => {
                          if (
                            key !== "_id" &&
                            key !== "UUID" &&
                            key !== "created_at"
                          ) {
                            // Exclude column with key "_id"
                            return (
                              <th
                                style={{
                                  textAlign: "center",
                                  background: "#EE1242",
                                  width: "auto",
                                  padding: "5px",
                                }}
                                key={i}
                              >
                                {key.replaceAll("_", " ")}
                              </th>
                            );
                          }
                          return null;
                        })}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {imsa &&
                      imsa.length > 0 &&
                      imsa.map((row, index) => {
                        const uuid = row.UUID; // Store UUID for navigation
                        return (
                          <tr key={index}>
                            {Object.entries(row).map(([key, value], i) => {
                              if (
                                key !== "_id" &&
                                key !== "UUID" &&
                                key !== "created_at"
                              ) {
                                return (
                                  <td
                                    key={i}
                                    style={{
                                      textAlign: "center",
                                      width: "auto",
                                      padding: "5px",
                                    }}
                                  >
                                    {key !== "Year" ? (
                                      <div>{value}</div>
                                    ) : (
                                      <div>
                                        {Array.isArray(value) ? (
                                          value.map((val, index) => (
                                            <a
                                              key={index}
                                              onClick={() => {
                                                console.log(uuid, "rowuk");
                                                navigate(
                                                  `/xfinitySecpage/${uuid}`,
                                                  {
                                                    state: {
                                                      /* Adjust this object as needed */
                                                    },
                                                  }
                                                );
                                              }}
                                              style={{
                                                width: "100%",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                                textDecoration: "none",
                                                display: "block",
                                              }}
                                              className="colorhover"
                                            >
                                              {val}
                                            </a>
                                          ))
                                        ) : (
                                          <a
                                            onClick={() => {
                                              console.log(uuid, "rowuk");
                                              navigate(
                                                `/ImsaSec/${uuid}`,
                                                {
                                                  state: {
                                                    /* Adjust this object as needed */
                                                  },
                                                }
                                              );
                                            }}
                                            style={{
                                              width: "100%",
                                              textAlign: "center",
                                              fontWeight: "bold",
                                              color: "white",
                                              cursor: "pointer",
                                              textDecoration: "none",
                                              display: "block",
                                            }}
                                            className="colorhover"
                                          >
                                            {value}
                                          </a>
                                        )}
                                      </div>
                                    )}
                                  </td>
                                );
                              }
                              return null;
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default ModifierTable;
