/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Section3 = () => {
  return (
    <>
      <section
        style={{
          // backgroundImage: 'url("/assets/images/banner/action-banner-1.jpg")',
          // minHeight: "71vh",
          // backgroundSize: "contain",
          // backgroundRepeat: "no-repeat",
          background: "black",
        }}
      >
        <div className="container">
          <div className="row pt-5 text-center">
            <div className="col-md-4">
              <h4 className="pb-3 text-light">Next NASCAR Cup Series Race</h4>
              <p className="banner2-para">
                "Xfinity 500", October 29th at Martinsville Speedway
              </p>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                Last race: "4EVER 400 Presented by Mobil 1"
              </p>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                2023 Nascar Cup schedule and results
              </p>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                Previous Nascar Cup races at Martinsville
              </p>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                Nascar Cup drivers' career stats at Martinsville
              </p>
            </div>
            <div className="col-md-4">
              <h4 className="text-light pt-1 pb-3">2023 Standings</h4>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                Last race: "4EVER 400 Presented by Mobil 1"
              </p>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                2023 Nascar Cup schedule and results
              </p>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                Previous Nascar Cup races at Martinsville
              </p>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                Nascar Cup drivers' career stats at Martinsville
              </p>
            </div>
            <div className="col-md-4">
              <h4 className="text-light pt-1 pb-3">Last Race</h4>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                Last race: "4EVER 400 Presented by Mobil 1"
              </p>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                2023 Nascar Cup schedule and results
              </p>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                Previous Nascar Cup races at Martinsville
              </p>
              <p className="banner2-para">
                <span>
                  <img
                    className="h-100"
                    src="/assets/images/in_check-circled-outline.png"
                    alt="Image"
                  />{" "}
                </span>
                Nascar Cup drivers' career stats at Martinsville
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section3;
