import React, { useEffect, useState } from 'react';
import Banner2 from '../Banner/Banner2';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Baseurl from '../BaseURL/Baseurl';
import axios from 'axios';

function CupSec() {
  const [CupData, setCupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { uuid } = useParams();
  const [tablename, setTablename] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOwnerData = async () => {
      try {
        const response = await axios.get(
          `${Baseurl.baseurl}get-cup-second-page/${uuid}`
        );
        console.log(response.data.table_data, "Cup2");
        setCupData(response.data.table_data || []); // Ensure CupData is always an array
        setTablename(response.data.table_name);
        setLoading(false);
      } catch (err) {
        console.error(err.response || err.message);
        setError(err.response?.data?.message || err.message);
        setLoading(false);
      }
    };

    fetchOwnerData();
  }, [uuid]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, []);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p className="text-white text-center">Error: {error}</p>;
  }

  return (
    <>
      <Banner2
        title="Cup Racer"
        slug="List"
        backgroundImage="/assets/images/banner/video-banner-1.jpg"
      />

      <section className="about-area pb-60 pt-60 bg-black">
        <div className="container">
          <section className="about-area pb-60 pt-60">
            <h5 className="pb-3 text-white text-center">{tablename}</h5>
            <hr style={{ color: "red" }} />
            <div className="tablees-container">
              {CupData && CupData.length > 0 ? (
                <table className="table table-dark table-striped table-bordered border-light">
                  <thead>
                    <tr>
                      {Object.keys(CupData[0]).map((key) => (
                        <th
                          style={{
                            textAlign: "center",
                            background: "#EE1242",
                            width: "auto",
                            padding: "5px",
                          }}
                          key={key}
                        >
                          {key.replace(/_/g, " ")}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {CupData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.entries(row).map(([key, value], valueIndex) => (
                          <td
                            style={{
                              textAlign: "center",
                              width: "auto",
                              padding: "5px",
                            }}
                            key={valueIndex}
                          >
                            {key !== "Year" && key !== "Races" && key !== "Champion" && key !== "usama" ? (
                              <div>{value}</div>
                            ) : (
                              <Link
                                onClick={() => {
                                  console.log(row.UUID, "rowuk"); // Log the UUID
                                  navigate(`/CupSecPage/${row.UUID}`.replace(/[.,]/g, ''), {
                                    state: {
                                      // Adjust this object as needed
                                    },
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "white",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                className="colorhover"
                              >
                                {typeof value === 'object' ? value[0] : value}
                              </Link>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>No data available</div>
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default CupSec;
