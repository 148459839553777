/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./page.css";
import Baseurl from "../BaseURL/Baseurl";
import { useDispatch, useSelector } from "react-redux";
import { setDrivers, selectDrivers } from "../../Redux/Reducer/Reducer";

const DriverTable = () => {
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState("");
  const navigate = useNavigate();
  const [showPagination, setShowPagination] = useState(true);

  const dispatch = useDispatch();
  const drivers = useSelector(selectDrivers);
  console.log(">>>>>>>>>", drivers);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Stop loader once data is fetched
      try {
        const response = await axios.get(
          `${Baseurl.baseurl}get-drivers-data-all`
        );
        // setData(response?.data?.records);
        dispatch(setDrivers(response?.data?.records));
        setLoading(false); // Stop loader once data is fetched
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    console.log(drivers, "drivers");
    if (!drivers.length) {
      fetchData();
    }
  }, [dispatch, drivers]);

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  // ------------

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);

  useEffect(() => {
    fetchDriversData();
  }, [currentPage]);

  const handleNext = () => {
    if (nextUrl) {
      setCurrentPage(currentPage + 1);
    }
    const tableSection = document.getElementById("table-section");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handlePrevious = () => {
    if (prevUrl && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    const tableSection = document.getElementById("table-section");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  function fetchDriversData() {
    let apiUrl = `https://api.racing-stats.com/get-drivers-data-all/?page=${currentPage}`;
    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data;
        setTotalPages("<<<<<>>>>>", data.records);
        dispatch(setDrivers(data.records));
        setPrevUrl(data.previous_url);
        setNextUrl(data.next_url);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }

  // -------fillter-----

  const [formData, setFormData] = useState({
    driver: "",
    born: "",
    died: "",
    home: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const apiUrl = `${Baseurl.baseurl}search_driver/?driver=${formData.driver}&home=${formData.home}&born=${formData.born}&died=${formData.died}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Handle API response here
        console.log(data);
        setFilterData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setShowPagination(false);
  };

  const isFormEmpty = Object.values(formData).every((value) => value === "");
  return (
    <div className="bg-black">
      <div className="container" id="table-section">
        <section className="about-area pb-60">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div
                  className="banner-filter-form mt-40"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <p className="text font-lg text-white font-bold">
                    I’m Looking for
                  </p>

                  <div
                    className="card p-30 shadow-lg bg-dark"
                    style={{ marginLeft: "-13px" }}
                  >
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <label
                              htmlFor="driver"
                              className="font-sm text-light"
                            >
                              Driver
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="driver"
                              name="driver"
                              value={formData.driver}
                              onChange={handleChange}
                              placeholder="Driver"
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <label
                              htmlFor="born"
                              className="font-sm text-light"
                            >
                              Born
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="born"
                              name="born"
                              value={formData.born}
                              onChange={handleChange}
                              placeholder="Born"
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <label
                              htmlFor="died"
                              className="font-sm text-light"
                            >
                              Died
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="died"
                              name="died"
                              value={formData.died}
                              onChange={handleChange}
                              placeholder="Died"
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                          <div className="form-group">
                            <label
                              htmlFor="home"
                              className="font-sm text-light"
                            >
                              Home
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="home"
                              name="home"
                              value={formData.home}
                              onChange={handleChange}
                              placeholder="Home"
                            />
                          </div>
                        </div>
                        <div className="col-md-1 col-sm-6 d-flex align-items-center justify-content-center">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                              background: "rgb(238, 18, 66)",
                              marginBottom: "-20px",
                            }}
                            disabled={isFormEmpty}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tablees-container">
            {loading ? (
              <Loader />
            ) : (
              <table className="table  table-dark table-striped table-bordered border-light">
                <thead>
                  <tr style={{ width: "100%", textAlign: "center" }}>
                    <th scope="col" style={{ background: "#EE1242" }}>
                      Driver
                    </th>
                    <th scope="col" style={{ background: "#EE1242" }}>
                      Born
                    </th>
                    <th scope="col" style={{ background: "#EE1242" }}>
                      Died
                    </th>
                    <th scope="col" style={{ background: "#EE1242" }}>
                      Home
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(filterData.records?.length > 0
                    ? filterData.records
                    : drivers
                  )?.map((item) => (
                    <tr key={item.id} style={{ width: "100%" }}>
                      <th
                        onClick={() =>
                          navigate(`/driver/${item.UUID}`, { state: { item } })
                        }
                      >
                        &nbsp; &nbsp;
                        {console.log(item.Driver, "usamaitm")}
                        <img
                          src={`https://${item.Rider_Country_Img}`} // Updated line
                          alt="Country"
                          onLoad={() =>
                            console.log(
                              `Image loaded: ${item.Rider_Country_Img}`
                            )
                          }
                          onError={() =>
                            console.error(
                              `Error loading image: ${item.Rider_Country_Img}`
                            )
                          }
                        />
                        &nbsp; &nbsp;
                        <Link className="namtext">{item.Driver}</Link>
                      </th>
                      <th style={{ textAlign: "center" }}>{item.Born}</th>
                      <th style={{ textAlign: "center" }}>{item.Died}</th>
                      <th style={{ textAlign: "center" }}>{item.Home}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {showPagination && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={handlePrevious}
                        style={{
                          background: "black",
                          color: "red",
                          height: "38px",
                          fontSize: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Previous
                      </button>
                    </li>
                    <h2
                      style={{
                        background: "black",
                        color: "red",
                        height: "38px",
                        border: "solid 1px white",
                        width: "35px",
                        fontSize: "20px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {currentPage}
                    </h2>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={handleNext}
                        style={{
                          background: "black",
                          color: "red",
                          height: "38px",
                          fontSize: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default DriverTable;
