import React from "react";

const Banner2 = ({ title, slug, backgroundImage }) => {
  const style = {
    backgroundImage: `url(${backgroundImage})`,
    height: "710px",
    width: "100%",
    backgroundSize: "cover", // Set background size to cover
  };
  return (
    <>
      {/* <div class="page-title-area pb-100 bg-img">
        <div class="container">
          <div class="content-slider d-flex justify-content-center ">
            <h1
              className="underline-small-h1"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {title}
            </h1>
            <h4 className="underline-small-h4">{slug}</h4>
          </div>
        </div>
      </div> */}
      <div className="page-title-area pb-100 bg-img" style={style}>
        <div className="container">
          <div className="content-slider d-flex justify-content-center">
            <h1
              className="underline-small-h1"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {title}
            </h1>
            <h4 className="underline-small-h4">{slug}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner2;
