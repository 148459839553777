/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Baseurl from "../BaseURL/Baseurl";
import axios from "axios";
import Banner2 from "../Banner/Banner2";
import userfake from "../../user-removebg-preview.png";

function TracksSecpage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(true); // Set isOpen to true initially
  const [, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Stop loader once data is fetched
      try {
        const response = await axios.get(
          `${Baseurl.baseurl}get-tracks-innerdata/${id}`
        );
        console.log(response?.data, "usama");
        setData(response?.data);
        setLoading(false); // Stop loader once data is fetched
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty array dena indicates ke useEffect sirf ek baar hi execute hoga component ka mount hone par

  const ComingSoon = () => {
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999,
        }}
      >
        {isOpen && (
          <div
            className="popup"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              textAlign: "center",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              border: "solid 1px red",
            }}
          >
            <div className="popup-content" style={{ color: "#333" }}>
              <h1 style={{ marginBottom: "10px", color: "red" }}>
                Coming Soon
              </h1>
              <p style={{ marginBottom: "20px", color: "white" }}>
                We're working on something exciting. Stay tuned!
              </p>
              <button
                onClick={togglePopup}
                style={{
                  backgroundColor: "#333",
                  color: "#fff",
                  border: "none",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <Banner2
        title="Racer"
        slug="List"
        backgroundImage="/assets/images/banner/video-banner-1.jpg"
      />
      <div className="bg-black">
        {!data.length && <ComingSoon />}
        <div className="container">
          {data[0]?.track_name && (
            // <div className="gradient-cardss" style={{ width: "auto" }}>
            //   <div className="cardd">
            //     <div className="container-cardd bg-green-box">
            //       <h6 className="card-titles underline-small-h1 color-primary">
            //         Track Name <hr className="color-white" />
            //         <h5 className="text-white">{data[0].track_name}</h5>
            //       </h6>
            //     </div>
            //   </div>
            // </div>
            <div className="gradient-cardss">
            <div className="carddreward">
              <div className="container-cardd bg-green-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <p className="card-titles underline-small-h1">
                    {data[0].track_name}
                  </p>
  
                  {/* <img
                    src={`https://${data.item.Rider_Country_Img}`} 
                    alt="Country"
                    style={{
                      scale: "1.7",
                      marginTop: "10px",
                      border: "solid 1px white",
                    }}
                    onLoad={() =>
                      console.log(
                        `Image loaded: ${data.item.Rider_Country_Img}`
                      )
                    }
                    onError={() =>
                      console.error(
                        `Error loading image: ${data.item.Rider_Country_Img}`
                      )
                    }
                  /> */}
                </div>
                {/* <img
                  src={
                    data.item.rider_image || userfake
                  }
                  alt="rider"
                  style={{
                    position: "absolute",
                    top: "-29px",
                    scale: "0.7",
                    right: "-20px",
                    borderRadius: "60px",
                  }}
                  onLoad={() =>
                    console.log(`Image loaded: ${data.item.rider_image}`)
                  }
                  onError={() =>
                    console.error(
                      `Error loading image: ${data.item.rider_image}`
                    )
                  }
                /> */}
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <p className="card-description">
                    <h5 style={{ color: "rgb(238, 18, 67)" }}>Born</h5>
                    <h6 className="card-titles" style={{ fontSize: "20px" }}>
                      {data.item.Born}
                    </h6>
                  </p>
                  <p className="card-description">
                    <h5 style={{ color: "rgb(238, 18, 67)" }}>Home</h5>
                    <h6 className="card-titles" style={{ fontSize: "20px" }}>
                      {data.item.Home}
                    </h6>
                  </p>
                  <p className="card-description">
                    <h5 style={{ color: "rgb(238, 18, 67)" }}>Died</h5>
                    <h6 className="card-titles " style={{ fontSize: "20px" }}>
                      {data.item.Died}
                      {console.log(data, "datad")}
                    </h6>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          )}

          <div
            className="container tablees-container"
            style={{
              border: "solid",
              borderRadius: "5px",
            }}
          >
            {data[0]?.all_tables?.map((e, index) => (
              <div className="tablees-container mt-3">
                <>
                  <p
                        className="tablnename text-center"
                        style={{ background: "gray", color: "white" }}
                      >
                        <b>{e.table_name.replaceAll("_", " ")}</b>
                      </p>
                  {loading ? (
                    <Loader />
                  ) : (
                    <table className="table  table-dark table-striped table-bordered border-light">
                      <thead>
                        <tr style={{ width: "100%", textAlign: "center" }}>
                          {Object.keys(e.table_data[0])
                            .slice(0, -2)
                            .map(
                              (key, i) =>
                                // Exclude 'year_uuid' from the header
                                key !== "year_uuid" && (
                                  <th
                                    style={{
                                      textAlign: "center",
                                      background: "#ee1243",
                                      width: "auto",
                                      padding: "5px",
                                    }}
                                    key={i}
                                  >
                                    {key}
                                    {/* Displaying the key of the 'data' object */}
                                  </th>
                                )
                            )}
                        </tr>
                      </thead>

                      <tbody>
                        {e?.table_data?.map((item, rowIndex) => {
                          return (
                            <>
                              <tr
                                style={{ width: "auto", textAlign: "center" }}
                              >
                                {console.log(item, "item")}
                                {Object.entries(item)
                                  .slice(0, -2)
                                  .map(([key, value], index) => (
                                    <td
                                      key={rowIndex}
                                      style={{
                                        width: "auto",
                                        textAlign: "center",
                                      }}
                                    >
                                      <span
                                        key={index}
                                        style={{
                                          textAlign: "center",
                                          width: "auto",
                                          padding: "5px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {value}
                                      </span>
                                    </td>
                                  ))}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default TracksSecpage;
