/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Baseurl from "../BaseURL/Baseurl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setModifier, selectModifier } from "../../Redux/Reducer/Reducer";
import { useNavigate } from "react-router-dom";

const ModifierTable = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const modifier = useSelector(selectModifier);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get(`${Baseurl.baseurl}api/get-modified/`);
        console.log(response?.data.arca_data, "dataIsma");
        dispatch(setModifier(response?.data.arca_data));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    console.log(modifier, "modifier");
    if (!modifier.length) {
      fetchData();
    }
  }, [dispatch, modifier]);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="about-area pb-60 pt-60 bg-black">
        <div className="container">
          <section className="about-area pb-60 pt-60">
            <div className="tablees-container">
              {loading ? (
                <Loader />
              ) : (
                <table className="table table-dark table-striped table-bordered border-light">
                  <thead>
                    {modifier && modifier.length > 0 && (
                      <tr>
                        {Object.entries(modifier[0]).map(
                          ([key], i) =>
                            // Exclude 'year_uuid' from the header
                            key !== "year_uuid" && (
                              <th
                                style={{
                                  textAlign: "center",
                                  background: "#ee1243",
                                  width: "auto",
                                  padding: "5px",
                                }}
                                key={i}
                              >
                                {key}
                              </th>
                            )
                        )}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {modifier &&
                      modifier.length > 0 &&
                      modifier.map((row, index) => {
                        const year_uuid = row.year_uuid; // Store year_uuid for later use
                        return (
                          <tr key={index}>
                            {Object.entries(row).map(
                              ([key, value], i) =>
                                // Exclude 'year_uuid' and 'created_at' from the body
                                key !== "year_uuid" &&
                                key !== "created_at" && (
                                  <td
                                    key={i}
                                    style={{
                                      textAlign: "center",
                                      width: "auto",
                                      padding: "5px",
                                    }}
                                  >
                                    {key !== "Year" &&
                                    key !== "usama" &&
                                    key !== "usama" ? (
                                      <div>{value}</div>
                                    ) : (
                                      <a
                                        onClick={() => {
                                          console.log(year_uuid, "year_uuid");
                                          navigate(
                                            `/ModifirerSec/${row.year_uuid}`,
                                            {
                                              state: {
                                                /* Adjust this object as needed */
                                              },
                                            }
                                          );
                                        }}
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          fontWeight: "bold",
                                          color: "white",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          display: "block",
                                        }}
                                        className="colorhover"
                                      >
                                        {typeof value === "object" ? (
                                          Object.values(value).map(
                                            (val, index) => (
                                              <div key={index}>{val}</div>
                                            )
                                          )
                                        ) : (
                                          <div>{value}</div>
                                        )}
                                      </a>
                                    )}
                                  </td>
                                )
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default ModifierTable;
