/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Baseurl from "../BaseURL/Baseurl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setArca, selectArca } from "../../Redux/Reducer/Reducer";
import { useNavigate } from "react-router-dom";

const CupTable = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const arca = useSelector(selectArca);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Baseurl.baseurl}api/get-arca/`);
        console.log(response?.data, "dataIsma");
        dispatch(setArca(response?.data.arca_data));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    console.log(arca, "arca");
    if (!arca.length) {
      fetchData();
    }
  }, [dispatch, arca]);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="about-area pb-60 pt-60 bg-black">
        <div className="container">
          <section className="about-area pb-60 pt-60">
            <div className="tablees-container">
              {loading ? (
                <Loader />
              ) : (
                <table className="table table-dark table-striped table-bordered border-light">
                  <thead>
                    {arca && arca.length > 0 && (
                      <tr>
                        {Object.entries(arca[0]).map(([key], i) => {
                          if (key !== "year_uuid") {
                            return (
                              <th
                                style={{
                                  textAlign: "center",
                                  background: "#ee1243",
                                  width: "auto",
                                  padding: "5px",
                                }}
                                key={i}
                              >
                                {key}
                              </th>
                            );
                          }
                          return null;
                        })}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {arca &&
                      arca.length > 0 &&
                      arca.map((row, index) => (
                        <tr key={index}>
                          {Object.entries(row).map(([key, value], i) => {
                            if (key !== "year_uuid" && key !== "created_at") {
                              return (
                                <td
                                  key={i}
                                  style={{
                                    textAlign: "center",
                                    width: "auto",
                                    padding: "5px",
                                  }}
                                >
                                  {key !== "Year" && key !== "usama" ? (
                                    <div>{value}</div>
                                  ) : (
                                    <a
                                      onClick={() => {
                                        console.log(row.year_uuid, "year_uuid");
                                        navigate(`/ArcaSec/${row.year_uuid}`, {
                                          state: {
                                            /* Adjust this object as needed */
                                          },
                                        });
                                      }}
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "white",
                                        cursor: "pointer",
                                        textDecoration: "none",
                                        display: "block",
                                      }}
                                      className="colorhover"
                                    >
                                      {typeof value === "object" ? (
                                        Object.values(value).map((val, index) => (
                                          <div key={index}>{val}</div>
                                        ))
                                      ) : (
                                        <div>{value}</div>
                                      )}
                                    </a>
                                  )}
                                </td>
                              );
                            }
                            return null;
                          })}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default CupTable;
