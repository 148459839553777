/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"; // eslint-disable-line
import axios from "axios"; // eslint-disable-
import Baseurl from "../BaseURL/Baseurl"; // esl
import { useDispatch, useSelector } from "react-redux";
import { setCup, selectCup } from "../../Redux/Reducer/Reducer";
import { useNavigate } from "react-router-dom";

const CupTable = () => {
  const [loading, setLoading] = useState(false); // set loading
  const [error, setError] = useState(null); // set error

  const dispatch = useDispatch();
  const cup = useSelector(selectCup);
  const navigate = useNavigate();
  useEffect(() => {
    // set loading
    const fetchData = async () => {
      // fetch data
      setLoading(true); // Stop loader once data is fetched
      try {
        // fetch
        const response = await axios.get(`${Baseurl.baseurl}get-nascar-stats`); // Get stats
        console.log(response, "dataCup"); // Display stats
        // setData(response?.data);
        dispatch(setCup(response?.data?.data));
        setLoading(false); // Stop loader once data is fetched
      } catch (err) {
        // Ignore
        setError(err.message); // Set Error message
        setLoading(false); // Stop loader once data is fetched
      } // Continue
    }; // Continue
    console.log(cup, "drivers");
    if (!cup.length) {
      fetchData();
    }
  }, [dispatch, cup]);

  console.log(cup, "uk");

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };
  return (
    <div className="bg-black">
      <div
        className="container"
        style={{
          border: "solid 1px gray",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <div className="tablees-container">
          {loading ? (
            <Loader />
          ) : (
            <table className="table table-dark table-striped table-bordered border-light">
              <thead>
                {cup && cup.length > 0 && (
                  <tr>
                    {Object.entries(cup[0]).map(
                      ([key], i) =>
                        !["_id", "created_at", "UUID"].includes(key) && (
                          <th
                            style={{
                              textAlign: "center",
                              background: "#ee1243",
                              width: "auto",
                              padding: "5px",
                            }}
                            key={i}
                          >
                            {key.replaceAll("_", " ")}
                          </th>
                        )
                    )}
                  </tr>
                )}
              </thead>
              {/* <tbody>
                {cup &&
                  cup.length > 0 &&
                  cup.map((row, index) => (
                    <tr key={index}>
                    {Object.entries(row)
                      .filter(
                        ([key]) =>
                          key !== "_id" && key !== "created_at" && key !== "UUID"
                      ) 
                      .map(([key, value], i) => (
                        <td
                          key={i}
                          style={{
                            textAlign: "center",
                            width: "auto",
                            padding: "5px",
                          }}
                        >
                          {key !== "Year" && key !== "Races" && key !== "Champion" && key !== "usama" ?   (
                            <div>
                               {value}
                            </div>
                          ) : (
                            <a
                              onClick={() => {
                                console.log(value, "rowuk"); 
                                navigate(`/CupSecPage/${value}`.replace(/[.,]/g, ''), {
                                  state: {
                                  
                                  },
                                });
                              }}
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontWeight: "bold",
                                color:"white",
                                cursor: "pointer",
                                textDecoration:"none",
                              }}
                              className="colorhover"
                            >
                              {typeof value === 'object' ? value[0]: value}
                            </a>
                          )}
                        </td>
                      ))}
                  </tr>
                  ))}
              </tbody> */}
              <tbody>
                {cup &&
                  cup.length > 0 &&
                  cup.map((row, index) => (
                    <tr key={index}>
                      {Object.entries(row)
                        .filter(
                          ([key]) =>
                            key !== "_id" &&
                            key !== "created_at" &&
                            key !== "UUID"
                        ) // Exclude '_id' and 'created_at' keys, but keep 'UUID'
                        .map(([key, value], i) => (
                          <td
                            key={i}
                            style={{
                              textAlign: "center",
                              width: "auto",
                              padding: "5px",
                            }}
                          >
                            {key !== "Year" &&
                            key !== "usama" &&
                            key !== "usama" &&
                            key !== "usama" ? (
                              <div>{value}</div>
                            ) : (
                              <a
                                onClick={() => {
                                  console.log(row.UUID, "rowuk"); // Log the UUID
                                  navigate(
                                    `/CupSec/${row.UUID}`.replace(/[.,]/g, ""),
                                    {
                                      state: {
                                        // Adjust this object as needed
                                      },
                                    }
                                  );
                                }}
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "white",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                className="colorhover"
                              >
                                {typeof value === "object" ? value[0] : value}
                              </a>
                            )}
                          </td>
                        ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default CupTable;
