/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Section6 = () => {
  return (
    <>
      <div className="section6">
        <p className="section6-heading" data-aos="fade-up" data-aos-delay="100">
          <span>
            <img
              className=""
              src="/assets/images/KoenigseggOne.png"
              alt="Image"
            />
            &nbsp; &nbsp;
          </span>
          2023 Season Information / Upcoming Races
        </p>
      </div>
      <section>

{/* section 1 */}

        <div className="row">
          <p className="section6-sub-heading" data-aos="fade-right" data-aos-delay="100">Next race: "Xfinity 500"</p>
        </div>
        <div className="row cup-series" data-aos="fade-right" data-aos-delay="100">
          <div>
            <img
              className="cup-series-img"
              src="/assets/images/Rectangle40.png"
              alt="Image"
            />
          </div>

       <div className="row cup-serires-text">
       <div className="col-md-4 text-center pt-5">
            <img
              className=""
              src="/assets/images/Group5.png"
              alt="Image"
            />
          </div>
          <div className="col-md-4 pt-5">
            <h6 className="cup-series-heading pb-2">
            October 29th at Martinsville Speedway
            </h6>
            <p className="cup-series-para">»Last race: "4EVER 400 Presented by Mobil 1"</p>
            <p className="cup-series-para">»2023 Nascar Cup schedule and results</p>
            <p className="cup-series-para">»Previous Nascar Cup races at Martinsville</p>
            <p className="cup-series-para">»Nascar Cup drivers' career stats at Martinsville</p>
          </div>
       </div>

       <div className="row cup-serires-detail">
       <div className='col-md-3'></div>

       <div className="col-md-3 col-sm-12  pt-5">
       <p className="cup-series-point">2023 Standings:1. William Byron (4126)</p>
            <p className="cup-series-point">2. Christopher Bell (4110 )</p>
            <p className="cup-series-point">3. Ryan Blaney (4106)</p>
            <p className="cup-series-point">4. Kyle Larson (4105)</p>
            <p className="cup-series-point">5. Tyler Reddick (4096)</p>
          </div>
          <div className="col-md-3 col-sm-12 pt-5">
           
            <p className="cup-series-point">6. Martin Truex Jr (4089)</p>
            <p className="cup-series-point">7. Denny Hamlin (4089)</p>
            <p className="cup-series-point">8. Chris Buescher (4063)</p>
            <p className="cup-series-point">9. Brad Keselowski (2262)</p>
            <p className="cup-series-point">10. Ross Chastain (2219)</p>
          </div>
          <div className='col-md-3'></div>

       </div>

        </div>

{/* section 2 */}


<div className="row">
          <p className="section6-sub-heading" data-aos="fade-left" data-aos-delay="100">Next race: "Dead On Tools 250"</p>
        </div>
        <div className="row cup-series text-end" data-aos="fade-left" data-aos-delay="100">
          <div>
            <img
              className="cup-series-img"
              src="/assets/images/Rectangle41.png"
              alt="Image"
            />
          </div>

       <div className="row cup-serires-text">
<div className='col-lg-4 col-md-0 col-sm-0'></div>
          <div className="col-lg-4 pt-5 ">
            <h6 className="cup-series-heading pb-2 text-end">
            Next race: "Dead On Tools 250"
            </h6>
            <p className="cup-series-para text-end">»Last race: "4EVER 400 Presented by Mobil 1"</p>
            <p className="cup-series-para text-end">»2023 Nascar Cup schedule and results</p>
            <p className="cup-series-para text-end">»Previous Nascar Cup races at Martinsville</p>
            <p className="cup-series-para text-end">»Nascar Cup drivers' career stats at Martinsville</p>
          </div>
          <div className="col-lg-4 text-center pt-5">
            <img
              className=""
              src="/assets/images/Group6.png"
              alt="Image"
            />
          </div>
       </div>

       <div className="row cup-serires-detail">
       <div className='col-md-3'></div>

       <div className="col-md-3 col-sm-12  pt-5 usa">
       <p className="cup-series-point">2023 Standings:1. William Byron (4126)</p>
            <p className="cup-series-point">2. Christopher Bell (4110 )</p>
            <p className="cup-series-point">3. Ryan Blaney (4106)</p>
            <p className="cup-series-point">4. Kyle Larson (4105)</p>
            <p className="cup-series-point">5. Tyler Reddick (4096)</p>
          </div>
          <div className="col-md-3 col-sm-12 pt-5">
           
            <p className="cup-series-point">6. Martin Truex Jr (4089)</p>
            <p className="cup-series-point">7. Denny Hamlin (4089)</p>
            <p className="cup-series-point">8. Chris Buescher (4063)</p>
            <p className="cup-series-point">9. Brad Keselowski (2262)</p>
            <p className="cup-series-point">10. Ross Chastain (2219)</p>
          </div>
          <div className='col-md-3'></div>

       </div>

        </div>







{/* section 3 */}
        <div className="row">
          <p className="section6-sub-heading" data-aos="fade-right" data-aos-delay="100">Next race: "Dead On Tools 250"</p>
        </div>
        <div className="row cup-series pb-5" data-aos="fade-right" data-aos-delay="100">
          <div>
            <img
              className="cup-series-img"
              src="/assets/images/Rectangle40.png"
              alt="Image"
            />
          </div>

       <div className="row cup-serires-text">
       <div className="col-md-4 text-center pt-5">
            <img
              className=""
              src="/assets/images/Group5.png"
              alt="Image"
            />
          </div>
          <div className="col-md-4 pt-5">
            <h6 className="cup-series-heading pb-2">
            November 3rd at Phoenix Raceway
            </h6>
            <p className="cup-series-para">»Last race: "Baptist Health 200"</p>
            <p className="cup-series-para">»2023 Craftsman Truck Series schedule and results</p>
            <p className="cup-series-para">»Previous Craftsman Truck Series races at Phoenix</p>
            <p className="cup-series-para">»Craftsman Truck Series drivers' career stats at Phoenix</p>
          </div>
       </div>

       <div className="row cup-serires-detail">
       <div className='col-md-3'></div>

       <div className="col-md-3 col-sm-12  pt-5">
       <p className="cup-series-point">2023 Standings:1. William Byron (4126)</p>
            <p className="cup-series-point">2. Christopher Bell (4110 )</p>
            <p className="cup-series-point">3. Ryan Blaney (4106)</p>
            <p className="cup-series-point">4. Kyle Larson (4105)</p>
            <p className="cup-series-point">5. Tyler Reddick (4096)</p>
          </div>
          <div className="col-md-3 col-sm-12 pt-5">
           
            <p className="cup-series-point">6. Martin Truex Jr (4089)</p>
            <p className="cup-series-point">7. Denny Hamlin (4089)</p>
            <p className="cup-series-point">8. Chris Buescher (4063)</p>
            <p className="cup-series-point">9. Brad Keselowski (2262)</p>
            <p className="cup-series-point">10. Ross Chastain (2219)</p>
          </div>
          <div className='col-md-3'></div>

       </div>

        </div>


      </section>
    </>
  );
};

export default Section6;
