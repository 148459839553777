/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import Baseurl from "../BaseURL/Baseurl";
import axios from "axios";
import "../XfinityTable/Xfinity.css";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import Banner2 from "../Banner/Banner2";
import userfake from "../../user-removebg-preview.png";

function CupSecPage() {
  const { id } = useParams();
  const { state } = useLocation();
  const itemdata = state;
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true); // Set isOpen to true initially
  const [data, setData] = useState([]);
  const [, setError] = useState(null);
  const navigate = useNavigate();
  const [isFakeImage, setIsFakeImage] = useState(false);

  console.log(data, "uk");
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Stop loader once data is fetched
      try {
        const response = await axios.get(
          `${Baseurl.baseurl}get-multiple-rider-data/?driver_name=${id}`
        );
        console.log(response?.data, "CupSecPageuk");
        setData(response?.data);
        // console.log(response, "dataIsma");

        setLoading(false); // Stop loader once data is fetched
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const ComingSoon = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999,
        }}
      >
        {isOpen && (
          <div
            className="popup"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              textAlign: "center",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              border: "solid 1px red",
            }}
          >
            <div className="popup-content" style={{ color: "#333" }}>
              <h1 style={{ marginBottom: "10px", color: "red" }}>
                Coming Soon
              </h1>
              <p style={{ marginBottom: "20px", color: "white" }}>
                We're working on something exciting. Stay tuned!
              </p>
              <button
                onClick={togglePopup}
                style={{
                  backgroundColor: "#333",
                  color: "#fff",
                  border: "none",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleImageLoad = () => {
    console.log(`Image loaded: ${data?.driver_info?.rider_image}`);
    if (!data?.driver_info?.rider_image) {
      setIsFakeImage(true);
    }
  };

  const handleImageError = () => {
    console.error(`Error loading image: ${data?.driver_info?.rider_image}`);
    setIsFakeImage(true);
  };

  const imageStyle = isFakeImage
    ? {
        position: "absolute",
        transform: "scale(0.6)",
        top: "-185px",
        right: "-95px",
      }
    : {
        position: "absolute",
        transform: "scale(0.8)",
        top: "-140px",
        right: "-35px",
      };

  return (
    <>
      <Banner2
        title="Cup Racer"
        slug="List"
        backgroundImage="/assets/images/banner/video-banner-1.jpg"
      />
      <div className="bg-black">
        {!data?.all_data?.length && <ComingSoon />}
        <div className="container">
          {data?.driver_info?.Driver && (
            <div className="gradient-cardss">
              <div className="carddreward">
                <div className="container-cardd bg-green-box">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <p className="card-titles underline-small-h1">
                      {data?.driver_info?.Driver}
                    </p>

                    <img
                      src={`https://${data?.driver_info?.Rider_Country_Img}`} // Updated line
                      alt="Country"
                      style={{
                        scale: "1.7",
                        marginTop: "10px",
                        border: "solid 1px white",
                      }}
                      onLoad={() =>
                        console.log(
                          `Image loaded: ${data?.driver_info?.Rider_Country_Img}`
                        )
                      }
                      onError={() =>
                        console.error(
                          `Error loading image: ${data?.driver_info?.Rider_Country_Img}`
                        )
                      }
                    />
                  </div>
                  <hr className="color-white" />{" "}
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      src={data?.driver_info?.rider_image || userfake}
                      alt="rider_image"
                      style={imageStyle}
                      onLoad={handleImageLoad}
                      onError={handleImageError}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <p className="card-description">
                      <h5 style={{ color: "rgb(238, 18, 67)" }}>Born</h5>
                      <h6 className="card-titles" style={{ fontSize: "20px" }}>
                        {data?.driver_info?.Born}
                      </h6>
                    </p>
                    <p className="card-description">
                      <h5 style={{ color: "rgb(238, 18, 67)" }}>Home</h5>
                      <h6 className="card-titles" style={{ fontSize: "20px" }}>
                        {data?.driver_info?.Home}
                      </h6>
                    </p>
                    <p className="card-description">
                      <h5 style={{ color: "rgb(238, 18, 67)" }}>Died</h5>
                      <h6 className="card-titles " style={{ fontSize: "20px" }}>
                        {data?.driver_info?.Died}
                      </h6>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="container tablees-container"
            style={{
              border: "solid",
              borderRadius: "5px",
            }}
          >
            {data?.all_data?.map((e, index) => (
              <>
                <p className="tablnename text-center  mt-3">
                  {e?.table_name.replaceAll("_", " ")}
                </p>
                <div className="tablees-container mt-3">
                  {loading ? (
                    <Loader />
                  ) : (
                    <table className="table  table-dark table-striped table-bordered border-light">
                      <thead>
                        <tr
                          key={index}
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          {e?.csv_data[0] &&
                            Object.keys(e.csv_data[0])
                              .slice(0, -2) // Exclude the last two keys
                              .map((k) => (
                                <th
                                  key={k}
                                  scope="col"
                                  style={{
                                    width: "auto",
                                    background: "#ee1243",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {k}
                                </th>
                              ))}
                        </tr>
                      </thead>
                      <tbody>
                        {e?.csv_data?.map((item, rowIndex, array, color) => (
                          <tr
                            key={rowIndex}
                            style={{
                              width: "auto",
                              textAlign: "center",
                              fontWeight:
                                rowIndex === array.length - 1
                                  ? "bold"
                                  : "normal", // Apply bold font weight to the last row
                              background:
                                color === array.length - 1 ? "green" : "white",
                            }}
                          >
                            {Object.entries(item)
                              .slice(0, -2)
                              .map(([key, value], index) => (
                                <td key={index}>
                                  {console.log(value, "value")}
                                  {key === "Races" || key === "Race" ? (
                                    <span
                                      onClick={() =>
                                        (item.Races || item.Race) &&
                                        navigate(
                                          item.Races || item.Race
                                            ? item.Races
                                              ? `/CupThirdPage/${item.races_uuid}`
                                              : `/CupForPage/${item.race_uuid}`
                                            : ""
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        cursor:
                                          item.Races || item.Race
                                            ? "pointer"
                                            : "default",
                                      }}
                                      className="colorhover"
                                    >
                                      {value}
                                    </span>
                                  ) : (
                                    value
                                  )}
                                </td>
                              ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CupSecPage;
