import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Baseurl from "../BaseURL/Baseurl";

const Ownerthree = () => {
  const [ownerData, setOwnerData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { uuid } = useParams();
  const [name, setName] = useState(null);
  const [tablename, setTablename] = useState(null);
  const [additional_text, setadditional_text] = useState(null);
  const [additional_data, setadditional_data] = useState([]);
  useEffect(() => {
    const fetchOwnerData = async () => {
      try {
        const response = await axios.get(
          `${Baseurl.baseurl}get-owner-third-page/${uuid}`
        );
        console.log(response.data.all_tables_data.additional_text, "Owner3");
        setadditional_data(response.data.all_tables_data.additional_data); // Ensure additional_data is always an array
        setadditional_text(response.data.all_tables_data.additional_text);
        setOwnerData(response.data.all_tables_data.table_data || []); // Ensure ownerData is always an array
        setTableData(response.data.all_tables_data.table || []); // Ensure ownerData is always an array
        setTablename(response.data.table_name);
        setName(response.data.owner_name);
        setLoading(false);
      } catch (err) {
        console.error(err.response || err.message);
        setError(err.response?.data?.message || err.message);
        setLoading(false);
      }
    };

    fetchOwnerData();
  }, [uuid]);

  if (loading) {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader">
          <img
            className="loaderimg"
            src="/assets/images/loader.gif"
            alt="loader"
          />
        </div>
      </div>
    );
  };

  const formattedTablename = tablename
    ? tablename.replace(/_/g, " ")
    : "No Table Name Provided";
  const displayName = name || "No Owner Name Provided";

  return (
    <section className="about-area pb-60 pt-60 bg-black">
      <div className="container">
        <section className="about-area pb-60 pt-60">
          <h2 className="pb-3 text-white text-center">{displayName}</h2>
          <h5 className="pb-3 text-white text-center">{formattedTablename}</h5>
          <hr style={{ color: "red" }} />
          <div className="tablees-container">
            {tableData && tableData.length > 0 ? (
              <table className="table table-dark table-striped table-bordered border-light">
                <thead>
                  <tr>
                    {Object.keys(tableData[0]).map((key) => (
                      <th
                        style={{
                          textAlign: "center",
                          background: "#EE1242",
                          width: "auto",
                          padding: "5px",
                        }}
                        key={key}
                      >
                        {key.replace(/_/g, " ")}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.values(row).map((value, valueIndex) => (
                        <td
                          style={{
                            textAlign: "center",
                            width: "auto",
                            padding: "5px",
                          }}
                          key={valueIndex}
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div> </div>
            )}
          </div>
          <div className="tablees-container">
            {ownerData.length === 0 ? (
              <p className="text-white text-center"> </p>
            ) : (
              <table className="table table-dark table-striped table-bordered border-light">
                <thead>
                  {ownerData.length > 0 && (
                    <tr>
                      {Object.keys(ownerData[0]).map((key) => (
                        <th
                          style={{
                            textAlign: "center",
                            background: "#EE1242",
                            width: "auto",
                            padding: "5px",
                          }}
                          key={key}
                        >
                          {key.replaceAll("_", " ")}
                        </th>
                      ))}
                    </tr>
                  )}
                </thead>
                <tbody>
                  {ownerData.map((item, itemIndex) => (
                    <tr key={itemIndex}>
                      {Object.values(item).map((value, valueIndex) => (
                        <td
                          style={{
                            textAlign: "center",
                            width: "auto",
                            padding: "5px",
                          }}
                          key={valueIndex}
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="tablees-container">
  {additional_data && additional_data.length === 0 ? (
    <p className="text-white text-center">No data available</p>
  ) : (
    additional_data && (
      <table className="table table-dark table-striped table-bordered border-light">
        <thead>
          {additional_data.length > 0 && (
            <tr>
              {Object.keys(additional_data[0]).map((key) => (
                <th
                  style={{
                    textAlign: "center",
                    background: "#EE1242",
                    width: "auto",
                    padding: "5px",
                  }}
                  key={key}
                >
                  {key.replaceAll("_", " ")}
                </th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {additional_data.map((item, itemIndex) => (
            <tr key={itemIndex}>
              {Object.values(item).map((value, valueIndex) => (
                <td
                  style={{
                    textAlign: "center",
                    width: "auto",
                    padding: "5px",
                  }}
                  key={valueIndex}
                >
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  )}
</div>

          <h5 className="pt-3 text-white text-center">{additional_text}</h5>
          
        </section>
      </div>
    </section>
  );
};

export default Ownerthree;
